import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    GET_FILLED_LOCATION,
    GET_ALL_SHIPMENT_BY_LOCATION,
    GET_GRID_VIEW,
    GET_GRID_DATA,
    GET_RACK_DATA,
    GET_GRID_CELL_DETAIL,
    GET_GRID_RACK_DETAIL,
    GET_GRID_ALL_DETAIL,
    GET_GRID_CELL_DIMENTION,
    GET_GRID_RACK_DIMENTION,
    GET_GRID_ALL_DIMENTION
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'


    export const enableDisableGridBox = (obj) => {
      return async (dispatch) => {
      
        dispatch({type: FETCH_START});
        await axios.post('shipment/changeGridBoxStatus',obj
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});      
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //console.log("Error****:", error.message);
        });
      }
    };

    export const renameGridBox = (obj) => {
      return async (dispatch) => {
      
        dispatch({type: FETCH_START});
        await axios.post('/dynamicShipment/renameGridBox',obj
        ).then(({data}) => {
  
          if (data.success)
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});    
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
           
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //console.log("Error****:", error.message);
        });
      }
    }

    export const addRack = (obj) => {
      return async (dispatch) => {
      
        dispatch({type: FETCH_START});
        await axios.post('/rack/addRack',obj
        ).then(({data}) => {
  
          if (data.success)
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});       
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
           
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //console.log("Error****:", error.message);
        });
      }
    }

    export const getGridDetail = (obj) => {
      return async (dispatch) => {
      
        dispatch({type: FETCH_START});
        await axios.post('/grid/getGridDetail',obj
        ).then(({data}) => {
          
          if (data.success)
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            // dispatch({type: SHOW_MESSAGE, payload: data.message});  
            if(obj.type=="block"){
              dispatch({type: GET_GRID_CELL_DETAIL, payload: data.data});  
            }
            else if(obj.type=="rack"){
              dispatch({type: GET_GRID_RACK_DETAIL, payload: data.data});  
            }  
            else{
              dispatch({type: GET_GRID_ALL_DETAIL, payload: data.data}); 
            }
              
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
              if(obj.type=="block"){
                dispatch({type: GET_GRID_CELL_DETAIL, payload: []});  
              }
              else if(obj.type=="rack"){
                dispatch({type: GET_GRID_RACK_DETAIL, payload: []});  
              }  
              else{
                dispatch({type: GET_GRID_ALL_DETAIL, payload: []}); 
              } 
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //console.log("Error****:", error.message);
        });
      }
    }

    export const getGridDimensions = (obj) => {
      return async (dispatch) => {
      
        dispatch({type: FETCH_START});
        await axios.post('/grid/getGridDimensions',obj
        ).then(({data}) => {
  
          if (data.success)
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            // dispatch({type: SHOW_MESSAGE, payload: data.message});  
            if(obj.type=="block"){
              dispatch({type: GET_GRID_CELL_DIMENTION, payload: data.data});  
            }
            else if(obj.type=="rack"){
              dispatch({type: GET_GRID_RACK_DIMENTION, payload: data.data});  
            }  
            else{
              dispatch({type: GET_GRID_ALL_DIMENTION, payload: data.data}); 
            }
              
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            if(obj.type=="block"){
              dispatch({type: GET_GRID_CELL_DIMENTION, payload: []});  
            }
            else if(obj.type=="rack"){
              dispatch({type: GET_GRID_RACK_DIMENTION, payload: []});  
            }  
            else{
              dispatch({type: GET_GRID_ALL_DIMENTION, payload: []}); 
            }
           
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //console.log("Error****:", error.message);
        });
      }
    }