import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
// import socketIOClient from "socket.io-client";
import ChangePassword from "./ChangePassword";


const UserProfile = ({user}) => {
  const dispatch = useDispatch();
  
  const [buttonState, setButtonState] = useState(false)

  const showModel = () => {
    setButtonState(true)
  }
  const onModelClose = () => {
    setButtonState(false)

  }
  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("shipmentID")
    localStorage.removeItem('user')
    dispatch(userSignOut())
  }
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={showModel}>Change Password</li>
      <li onClick={signOut}>Logout</li>
    </ul>
  );

  return (
    <div>

      <div className="gx-flex-row gx-align-items-center gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click" className="gx-flex-row gx-align-items-center gx-justify-content-between">
          {/* <Avatar src={require("assets/images/user.png")}
            className="gx-size-40 gx-pointer gx-mr-3 gx-shadow" alt="" /> */}
          {user && user.name
            ?<span className="gx-avatar-name gx-flex-row gx-align-items-start gx-justify-content-between">

              <span className="gx-avatar-name gx-flex-column gx-align-items-end gx-justify-content-between">
                <span className="gx-text-capitalize gx-fs-sm">{user.name}</span>
                <span className="gx-text-capitalize gx-fs-sm">{user.company}</span>
              </span>
              <span className="gx-avatar-name gx-flex-column gx-align-items-start gx-justify-content-between">
                  <i className="icon icon-chevron-down gx-fs-sm gx-ml-2" />
              </span>
            </span>
            : "loading"
          }
        </Popover>
      </div>

      <ChangePassword
        open={buttonState}
        onModelClose={onModelClose}
      />
    </div>

  )

}




export default UserProfile;
