import {GET_DASHBOARD} from '../../constants/ActionTypes';


const intialState = {
    dashboardList:[]
 };


 const dashboardReducer = (state = intialState, { type, payload }) => {
    switch (type) { 
        case GET_DASHBOARD:       
            return { ...state,
                dashboardList:payload
            } 
        default:
            return state;
    }
}
export default dashboardReducer;