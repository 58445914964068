import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    GET_SHIPMENT_REPORT,
    GET_WMS_REPORT,
    GET_WMS_REPORT_EXCEL,
    GET_WMS_REPORT_PDF,
   
  } from "../../constants/ActionTypes";
  import axios from "../../util/Api";
  //import axios from 'axios';

  // Invetuary report 
export const getShipmentReport = (obj) => {
    return async (dispatch) => {
    
      dispatch({type: FETCH_START});
      await axios.post('/report/getShipmentReport',obj
      ).then(({data}) => {
  
        if (data.success)
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
              
          dispatch({type: GET_SHIPMENT_REPORT, payload: data});    
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
          dispatch({type: GET_SHIPMENT_REPORT, payload: {}});
         
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  }
export const getWMSReport = (obj,callBackFunction) => {
    return async (dispatch) => {
    
      dispatch({type: FETCH_START});
      await axios.post('/report/getWMSReport',obj
      ).then(({data}) => {
        const {sendToEmail}=obj
        if (data.success)
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          if(!sendToEmail){
            if(obj.downloadType=="html"){
              dispatch({type: GET_WMS_REPORT, payload: data}); 
              dispatch({type: GET_WMS_REPORT_EXCEL, payload: {}});  
              dispatch({type: GET_WMS_REPORT_PDF, payload: {}});    
            }
            else if(obj.downloadType=="excel"){
              dispatch({type: GET_WMS_REPORT_EXCEL, payload: data}); 
              dispatch({type: GET_WMS_REPORT_PDF, payload: {}});
            }
            else if(obj.downloadType=="pdf"){
              dispatch({type: GET_WMS_REPORT_PDF, payload: data}); 
              dispatch({type: GET_WMS_REPORT_EXCEL, payload: {}});  
            }
          }
        } 
        else 
        {
          if(!sendToEmail){
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({type: GET_WMS_REPORT, payload: {}});  
            dispatch({type: GET_WMS_REPORT_EXCEL, payload: {}});  
            dispatch({type: GET_WMS_REPORT_PDF, payload: {}});  
          }
        }

        callBackFunction({success:data.success || false,message:data.message || ""})
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  }
