import React,{useState} from 'react'
import RightNav from './RightNav';

const Burger = () => {
    const [open, setOpen] = useState(false);
   
    return (
      <>
        <div className='web-burger' open={open} onClick={() => setOpen(!open)}>
          <div className={open?'headline1':'head-line1'}/>
          <div className={open?'headline2':'head-line2'}/>
          <div className={open?'headline3':'head-line3'}/>
        </div>
        <RightNav open={open}/>
      </>
    )
  }
  
  export default Burger;
