import {
    GET_COMPONENT_LIST,
    GET_REGEX_LIST,
    GET_API_LIST,
    GET_TEMPLATE_LIST,
    GET_TEMPLATE_DETAIL,
    GET_TEMPLATE_DATA,
    GET_TEMPLATE_DATA_BY_SHIPMENT,
    GET_TEMPLATE_DATA_BY_LOCATION,
    GET_GRID_BLOCK_DATA_BY_LOCATION,
    VIEW_FILLED_LOCATION_BY_SHIPMENT,
    GET_TEMPLATE_DATA_BY_PRODUCT_TYPE,
    GET_SEAL_DATA,
    GET_ALL_PRODUCT_TYPE,
    GET_ALL_JOB,
    GET_PACKAGE_DETAILS,
    GET_FILE_URL,
    GET_SKU_HEADER,
    GET_TEMPLATE_HEADER,
    VALIDATE_PRODUCT_MASTER_DESCRIPTION,
   GET_PRODUCT_MASTER,
    GET_FREE_TEMPLATE,
    GET_PACKAGE_DETAIL_TEMPLATE,
    GET_JOB_DETAIL
   
} from "../../constants/ActionTypes";


const intialState = {
    ComponentList: [],
    RegextList: [],
    APIList:[],
    TemplateList:{},
    FreeTemplateList:{},
    PackageDetailTemplateList:{},
    TemplateDetail:{},
    TemplateHeader:{},
    TemplateData:{},
    TemplateDataByShipment:{},
    TemplateDataByLocation:{},
    TemplateDataByProductType:[],
    FilledLocationByShipment:{},
    SealData:[],
    AllProductType:[],
    ALLJobs:{},
    jobDetail:{},
    packageDetails:{},
    fileUrl:{},
    ProductMasterData:{},
    MasterDescription:{},
    skuHeader:{}
};


const dynamicFormReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_COMPONENT_LIST:
            return { ...state, ComponentList: payload }

        case GET_REGEX_LIST:
            return { ...state, RegextList: payload }

        case GET_API_LIST:
            return { ...state, APIList: payload }
            
        case GET_TEMPLATE_LIST:
            return { ...state, TemplateList: payload }
        case GET_FREE_TEMPLATE:
            return { ...state, FreeTemplateList: payload }
        case GET_PACKAGE_DETAIL_TEMPLATE:
            
            return { ...state, PackageDetailTemplateList: payload }

        case GET_TEMPLATE_DETAIL:
            return { ...state, TemplateDetail: payload }
        
        case GET_TEMPLATE_HEADER:
            return { ...state, TemplateHeader: payload }
    
        case GET_TEMPLATE_DATA:
            return { ...state, TemplateData: payload }
        case GET_TEMPLATE_DATA_BY_PRODUCT_TYPE:
            return { ...state, TemplateDataByProductType: payload.data }

        case GET_TEMPLATE_DATA_BY_SHIPMENT:
            return { ...state, TemplateDataByShipment: payload}
        case GET_TEMPLATE_DATA_BY_LOCATION:
            return { ...state, TemplateDataByLocation: payload.shipmentData }
        case GET_GRID_BLOCK_DATA_BY_LOCATION:
            return { ...state, gridBlockDataByLocation: payload }
        case VIEW_FILLED_LOCATION_BY_SHIPMENT:
            return { ...state, FilledLocationByShipment: payload }
        case GET_SEAL_DATA:
            return { ...state, SealData: payload }
        case GET_ALL_PRODUCT_TYPE:
            return { ...state, AllProductType: payload }
        case GET_ALL_JOB:
            return { ...state, ALLJobs: payload }
        case GET_JOB_DETAIL:
            return { ...state, jobDetail: payload }
        case GET_PACKAGE_DETAILS:
            return { ...state, packageDetails: payload }
        case GET_FILE_URL:
            return { ...state, fileUrl: payload }
        case GET_SKU_HEADER:
            return { ...state, skuHeader: payload }
        case VALIDATE_PRODUCT_MASTER_DESCRIPTION:
            return { ...state, MasterDescription: payload }
        case GET_PRODUCT_MASTER:
            return { ...state, ProductMasterData: payload }
        default:
            return state;
    }
}
export default dynamicFormReducer;