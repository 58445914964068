import {
    GET_DAMAGE_COLLECTION_DATA
} from "../../constants/ActionTypes";



const intialState = {
    damageAreaData:[]
};


const damageReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_DAMAGE_COLLECTION_DATA:
            return { ...state, damageAreaData:payload }
        
        default:
            return state;
    }
}
export default damageReducer;