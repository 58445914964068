import { GET_HISTORY } from "../../constants/ActionTypes";

const intialState = {
    history: []
};

const historyReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_HISTORY:
            return { ...state, history: payload };

        default:
            return state;
    }
};
export default historyReducer;