import React, { useRef, useEffect, useState } from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import Auxiliary from "util/Auxiliary";
import UserProfile from "../Sidebar/UserProfile"
import { connect } from "react-redux";


const { Header } = Layout;

const Topbar = ({user={}}) => {
 

  const [state, setState] = useState({
    isVisible: false,
    arrForLogo: ['zones', 'map', 'indoorTracking', 'devicereport', 'zonereport', 'devices', 'map', 'assets', 'reports', 'tracking',"main_dashboard"]
  })

 

  useEffect(() => {
   
      let arr = window.location.pathname.split('/')
      let isValid = false
      for (let data of arr) {
        if (state.arrForLogo.includes(data)) {
          isValid = true
          break;
        }
      }
      if (isValid) {
        setState({ ...state, isVisible: true })
      }
      else {
        setState({ ...state, isVisible: false })
      }
  },[window.location.pathname])
  return (
    <Auxiliary>
      <Header>
        <Link to="/home" className="gx-site-logo">
          { state.isVisible
              ?<img alt="" src={require("assets/images/lynkitid-logo.png")} style={{ width: '120px' }} />
              :user && user.base== "Visitor"
                ? <img alt="" src={require("assets/images/lynkit.png")} style={{ width: '120px' }} />
                :<img alt="" src={require("assets/images/logo-white.png")} style={{ width: '120px' }} />
          }
        </Link>
        <ul className="gx-header-notifications gx-ml-auto">
          <li className="gx-language">
            <UserProfile user={user}/>
          </li>
        </ul>
      </Header>
    </Auxiliary>
  );
}


export default Topbar;
