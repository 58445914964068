import {
    ADD_ASSET,
    ADD_GATEWAY,
    GET_GATEWAY,
    GET_ASSET,
    GET_TRACK_BY_DEVICE
} from "../../constants/ActionTypes";


const intialState = {
   allGatway:[],
   allAsset:[],
   gatewayCount:'',
   assetCount:'',
   deviceTrack:[]
};


const assetReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case ADD_GATEWAY:
            //console.log(payload,'reducer')
            return { ...state }
        case ADD_ASSET:
            //console.log(payload, 'reducer')
            return { ...state }
        case GET_GATEWAY:
           // console.log(payload,'reducer')
            return { ...state,allGatway:payload.data,gatewayCount:payload.count}
        case GET_ASSET:
            //console.log(payload,'reducer')GET_TRACK_BY_DEVICE
            return { ...state,allAsset:payload.data,assetCount:payload.count }
        case GET_TRACK_BY_DEVICE:
            //console.log(payload,'reducer')
            return { ...state,deviceTrack:payload}
        default:
            return state;
    }
}
export default assetReducer;