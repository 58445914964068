import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  EDIT_COMPANY,
  GET_COMPANY,
  ADD_COMPANY,
  GET_WAREHOUSE,
  GET_FLOOR,
  GET_ALL,
  DELETE_COMPANY,
  GET_ADMIN,
  DELETE_ADMIN,
  GET_FLOORS,
  SET_FLOOR,
  ADD_GRID,
  GET_ALL_PERMISSIONS,
  GENERATE_QR,
  // GET_WAREHOUSE_ANALYTICS,
  // GET_FLOOR_ANALYTICS,
  GET_ALL_ANALYTICS,
  // GET_DATE_ANALYTICS
} from "../../constants/ActionTypes";
import axios from "../../util/Api";



export const addCompanyData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/addCompany", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });

      }
    });
  };
};
export const addGridData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/grid/addGridDetails", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });

      }
    })
  };
};
export const addFloorData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/addFloor", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message ? data.message : "Floor added successfully" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};
export const addWarehouseData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/addWarehouse", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message ? data.message : "Warehouse added successfully" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });

      }
    });
  };
};

export const editCompany = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/editCompany", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};

export const editWarehouse = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/editWarehouse", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message ? data.message : "Warehouse updated successfully" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};

export const editFloor = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/editFloor", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message ? data.message : "Floor updated successfully" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};
export const getWarehouseAnalytics = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/report/warehouseAnalytics", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({type:GET_ALL_ANALYTICS, payload:data})
        } 
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({type:GET_ALL_ANALYTICS, payload:{}})
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getCompany = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/getCompany", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        // dispatch({ type: SHOW_MESSAGE, payload: data.message });
        if (obj.type && obj.type == "company") {
          dispatch({ type: GET_COMPANY, payload: data });
        }
        else if (obj.type && obj.type == "floor") {
          dispatch({ type: GET_FLOOR, payload: data.data });
        }
        else if (obj.type && obj.type == "warehouse") {
          dispatch({ type: GET_WAREHOUSE, payload: data });
        }
        else {
          dispatch({ type: GET_ALL, payload: data.data });
        }

      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        if (obj.type == "company") {
          dispatch({ type: GET_COMPANY, payload: {} });
        }
        else if (obj.type == "floor") {
          dispatch({ type: GET_FLOOR, payload: [] });
        }
        else if (obj.type == "warehouse") {
          dispatch({ type: GET_WAREHOUSE, payload: [] });
        }
        else{
          dispatch({ type: GET_ALL, payload: [] });
        }
      }
    });
  };
};

export const setFloorInStore = obj => {

  return dispatch => {
    dispatch({ type: SET_FLOOR, payload: obj });
  }

}
export const getAllPermissions = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("admin/getAllPermission", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: GET_ALL_PERMISSIONS, payload: data.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: GET_ALL_PERMISSIONS, payload: [] });
      }
    });
  };
};


export const updateCompanyStatus = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/updateCompanyStatus", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};
export const updateAdminStatus = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/admin/changeAdminStatus", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};

export const addAdminData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/admin/addAdmin", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
        dispatch(getAdmin());
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};
export const getAdmin = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/admin/getAdmin", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        // dispatch({ type: SHOW_MESSAGE, payload: data.message });
        dispatch({ type: GET_ADMIN, payload: data });
      } else {
       // dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: GET_ADMIN, payload: {} });
      }
    });
  };
};
export const deleteAdmin = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/deleteUser", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
        dispatch({ type: DELETE_ADMIN, payload: data._id });

      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};

export const editUser = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/admin/editAdmin", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch(getAdmin)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const generateQr = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/generateQR", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: GENERATE_QR, payload: data.downloadURL})
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GENERATE_QR, payload: [] });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};



// export const downloadQr = () => {
//    axios
//       .get("http://warehouse.lynkgrid.com/v1/downloadQR/634933dba3e20f7e47248d78")
// };