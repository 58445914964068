import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    GET_SEARCH_TAG,
   
  } from "../../constants/ActionTypes";
  import axios from "../../util/Api";


export const addSearchTag = (obj) => {
    return async (dispatch) => {
    
      dispatch({type: FETCH_START});
      await axios.post('/searchTag/addSearchTag',obj
      ).then(({data}) => {
        if (data.success || !data.error) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
             dispatch({type: SHOW_MESSAGE, payload: data.message});
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  }
export const getSearchTag = (obj) => {
    return async (dispatch) => {
    
      dispatch({type: FETCH_START});
      await axios.post('/searchTag/getSearchTag',obj
      ).then(({data}) => {
        if (data.success)
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: GET_SEARCH_TAG, payload: data}); 
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
          dispatch({type: GET_SEARCH_TAG, payload: {}});  
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  }