import AxiosInatance from "axios";
import config from "./config";
import { message } from "antd";



const axios= AxiosInatance.create({
  baseURL: config.baseURL || `/v1/`,
  headers: {
    "Content-Type": "application/json"
  }
});

axios.interceptors.response.use(function (response) {

  // Do something with response data
  // console.log("response status>>>", response);

  if ( response.data && response.data.inValidToken == true) {//response.status == 201 &&
    window.localStorage.clear();
    window.localStorage.setItem("TokenExpiryMessage", response.data.message || "Session expired, please logout and login again!")
    window.location.href = '/signin';
  }
 
  return response

}, function (error) {
  // Do something with response error
  // console.log("error.response>>>", error);
  return Promise.reject(error);
});


export default axios;



