import React,{useEffect, useState} from "react"; 
import { connect,useSelector ,useDispatch} from "react-redux";
import { Input, Modal, Form, Button, notification,Col,Row } from "antd";
import { changePassword } from "../../appRedux/actions/WmsUser";



const FormItem=Form.Item

const PasswordModel = (props) => {
    const dispatch = useDispatch();

    const {onModelClose, open } = props;
    const { message, error } = useSelector((state) => state.commonData);
    const { getFieldDecorator, getFieldsError, isFieldsTouched, getFieldError, isFieldTouched } = props.form;

    const [conditionRender ,setConditionRender]=useState(false)
    
    const submitHandler = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                dispatch(changePassword(
                    {
                        "old": values.oldPassword,
                        "password": values.newPassword
                    } ))
                    setConditionRender(true)
            }
            else {
                console.log(err, 'validation error');
            }
        });
    }
    const handleResetForm = () => {
        props.form.resetFields();

    };
    const hasErrors = (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    const oldPasswordError = isFieldTouched('oldPassword') && getFieldError('oldPassword');
    const newPasswordError = isFieldTouched('newPassword') && getFieldError('newPassword');
    
    const onCancel = () => {     
        onModelClose();
        handleResetForm();
    }

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
    }
    useEffect(() => {
        if(conditionRender){
            message && notification.success({
                message: `Success`,
                description: message,
                placement: 'bottomRight',
            });
            message && onCancel();
           
            error && notification.error({
                message: `Error`,
                description: error,
                placement: 'bottomRight',
            });
        }
    },[message, error])

    return (
        <Modal
            title={<span className="gx-text-dark-light gx-font-weight-semi-bold">Change Password?</span>}
            footer={''}
            toggle={onCancel}
            visible={open}
            closable={false}
            onCancel={onCancel}
        >
            <div className="Form-contain">

                <Form
                   className="gx-signin-form gx-form-row"
                   {...formItemLayout}
                   onSubmit={submitHandler}
                   name="basic"
                   initialValues={{ remember: true }}
                   autoComplete="off"
                   >
                    <Row gutter={[{ xs: 32, sm: 28, md: 26, lg: 24 }, 5]} className="row">

                        <Col className="column" flex="1 0 25%" span={24}>
                            <FormItem
                                name='oldPassword'
                                hasFeedback
                                validateStatus={oldPasswordError ? 'error' : ''}
                                help={oldPasswordError || ''}
                            >
                                <div>
                                    <span className="gx-mb-2">Old Password<span className="gx-text-primary">*</span></span>
                                </div>
                                {getFieldDecorator('oldPassword', {
                                    initialValue: "",
                                    rules: [{
                                        required: true,
                                        message: 'Old Password is is required !',
                                    },
                                    {
                                        pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/),
                                        message: 'Password must be at least 6 characters i.e Lynk@1!',
                                    },

                                    ],
                                })(
                                     <Input.Password
                                        style={{
                                            width: '100%',
                                        }}
                                        className="input-field gx-mt-1"
                                        //onChange={handleChange}
                                        margin="none"
                                        size='large'
                                        required
                                        prefix={ <i className="fa fa-envelope-o gx-mr"></i>}

                                    />
                                    
                                )}
                            </FormItem>
                        </Col>
                        <Col className="column" flex="1 0 25%" span={24}>
                            <FormItem
                                name='newPassword'
                                hasFeedback
                                validateStatus={newPasswordError ? 'error' : ''}
                                help={newPasswordError || ''}
                            >
                                <div>
                                    <span className="gx-mb-2">New Password<span className="gx-text-primary">*</span></span>
                                </div>
                                {getFieldDecorator('newPassword', {
                                    initialValue: "",
                                    rules: [{
                                        required: true, message: 'New Password is required !',
                                       
                                    },
                                    {
                                        pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/),
                                        message: 'Password must be in required format !',
                                    },
                                    //{ min: 6 },

                                    ],
                                })(
                                    <Input.Password
                                        style={{
                                            width: '100%',
                                        }}
                                        className="input-field gx-mt-1"
                                        //onChange={handleChange}
                                        margin="none"
                                        size='large'
                                        required
                                        prefix={ <i className="fa fa-envelope-o gx-mr"></i>}

                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem wrapperCol={{ span: 24 }}>
                        <div className='gx-flex-row gx-justify-content-center gx-align-items-center gx-mt-4 '>

                            <Button type="primary" className='gx-shadow-less' htmlType="submit" disabled={hasErrors(getFieldsError())}>Change Password</Button>
                            {/* disabled={resetState} disabled={buttonState} disabled={hasErrors(getFieldsError())}  onClick={submitHandler}*/}

                            <Button className='gx-shadow-less' onClick={onCancel}>Close</Button>
                        </div>
                    </FormItem>
                </Form>
            </div>
        </Modal>
    );

}

let WrappedNormalForm = Form.create()(PasswordModel)

export default connect(({ PasswordModel }) => ({
    PasswordModel
}))(WrappedNormalForm);



