import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  GET_ZONE,CREATE_ZONE,
  EDIT_ZONE,
  DELETE_ZONE,
  GET_TRACK_BY_ZONE
} from "../../constants/ActionTypes";
import axios from '../../util/Api'

export const createZone= (obj) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('zone/createZone',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };


export const getZone = () => {
    return async (dispatch) => {
      dispatch({type: FETCH_START});
      await axios.post('zone/getZone'
      ).then(({data}) => {
        
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: GET_ZONE, payload: data.zone});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const editZone= (obj) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('zone/updateZone',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const delteZone = (obj) => {
    return async (dispatch) => {
      dispatch({type: FETCH_START});
      await axios.post('zone/deleteZone',obj
      ).then(({data}) => {
        //console.log('zone',data);
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          //dispatch({type: DELETE_ZONE, payload: data.zone});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const getTrackByZone= (obj) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
    //console.log(obj,'track');
      axios.post('track/getTrackByZone',obj
      ).then(({data}) => {
         //console.log(data,'track');
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
         dispatch({type: GET_TRACK_BY_ZONE, payload: data.data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };