import React from "react";
import {Button, Form, Input,notification,message} from "antd";
import {connect} from "react-redux";
import {userSignIn} from "../appRedux/actions/Auth";
import InfoView from "../components/InfoView";
import "./styles.css"
import { Link } from "react-router-dom";


// #e75e2e
const FormItem = Form.Item;
const displayMessage=message

class SignIn extends React.Component {

  constructor(props){
    super(props)
    if (props.token) { 
      props.history.push('/home');
    }
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values);
      }
    });
    
  };

  componentDidUpdate() {
    if (this.props.token) {
      this.props.history.push('/');
    }
  }
  componentDidMount() {
    let TokenExpiryMessage=window.localStorage.getItem("TokenExpiryMessage")
    if (TokenExpiryMessage) {
      displayMessage.error(TokenExpiryMessage)
      window.localStorage.clear()
    }
  }

  render() {
    const {getFieldDecorator, getFieldsError} = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            
            <div className="gx-app-logo-content gx-align-items-center gx-justify-content-center">
              <div className="gx-app-logo">
                 <Link to="/"><img className="gx-app-logo-img" alt="signin-logo" src={require("../assets/images/logo-white.png")}/> </Link>
                 <br/>
                 {/* <span className="gx-app-logo-text gx-mt-5 ">Login</span> */}
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    }],
                  })(
                    <Input className="input-field" placeholder="Enter email" type="email" data-testid="email-input" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{
                      required: true, message: 'Password is required!',

                    },
                    {
                      pattern: new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                      message: 'Password must be minimum 8 characters and in currect format  i.e Lynk@123!',
                    },
                    ],
                  })(
                    <Input className="input-field" type="password" placeholder="Enter password" data-testid="password-input"/>
                  )}
                </FormItem>
                
                <FormItem className="button-submit" >
                  <Button  disabled={hasErrors(getFieldsError())} role="signInUserButton" type="primary" className="gx-mb-0 login-button-input" htmlType="submit" data-testid="submit-input">
                    Login
                  </Button>
        
                </FormItem>
                {/* <span
                  className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }

}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}