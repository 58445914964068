import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  DELETE_JOBTYPE,
  GET_JOBTYPE,
  EDIT_JOBTYPE
} from "../../constants/ActionTypes";
import axios from "../../util/Api";

export const addJobType = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/dynamicShipment/addJobType", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};

export const getJobTypes = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/dynamicShipment/getJobTypes", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        // dispatch({ type: SHOW_MESSAGE, payload: data.message });
        dispatch({ type: GET_JOBTYPE, payload: data });
      } else {
        let item={...data,data:[],count:0}
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: GET_JOBTYPE, payload: item });
      }
    });
  };
};

export const editjobtype = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/dynamicShipment/updateJobType", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};

export const deletejobtype = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/dynamicShipment/deleteJobType", obj).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
        dispatch({ type: DELETE_JOBTYPE, payload: obj.id });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    });
  };
};
