import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    GET_ZONE_USER,
    TRACK_USER
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'
  
  export const getZoneUser= (obj) => {
      return (dispatch) => {
        dispatch({type: FETCH_START});
       
        axios.post('zone/getAllUserData',obj
        ).then(({data}) => {
          
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            dispatch({type: GET_ZONE_USER, payload: data});
          } 
          else 
          {
            //dispatch({type: FETCH_ERROR, payload: data.message});
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          console.log("Error****:", error.message);
        });
      }
    };
    export const trackUser= (obj) => {
      return (dispatch) => {
        dispatch({type: FETCH_START});
      //console.log(obj,'track');
        axios.post('track/getTrack',obj
        ).then(({data}) => {
           //console.log(data,'track');
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            dispatch({type: TRACK_USER, payload: data.data});
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          console.log("Error****:", error.message);
        });
      }
    };