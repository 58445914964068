import {
  EDIT_COMPANY,
  GET_ADMIN,
  GET_COMPANY,
  ADD_COMPANY,
  GET_WAREHOUSE,
  GET_FLOOR,
  GET_ALL,
  DELETE_COMPANY,
  DELETE_ADMIN,
  GET_FLOORS,
  SET_FLOOR,
  GET_ALL_PERMISSIONS,
  GENERATE_QR,
  // GET_WAREHOUSE_ANALYTICS,
  // GET_FLOOR_ANALYTICS,
  GET_ALL_ANALYTICS,
  // GET_DATE_ANALYTICS,
} from "../../constants/ActionTypes";


const intialState = {
  superadmin: {},
  companies: {},
  //current
  allPermissions:[],
  warehouseDetail: {},
  floorDetail: [],
  allWFDetail:[],
  allAnalytics:{},
  // warehouseAnalytics:{},
  // floorAnalytics:[],
  generatedQr:undefined,
  //incomming
  // warehouse:[],
  // floors:[],
  selectedFloor:undefined
};

const adminReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case GET_ADMIN:
      return { ...state, superadmin: payload };
    case GET_COMPANY:
      return { ...state, companies: payload };
   
    case GET_FLOOR:
      return { ...state, floorDetail: payload };
    case GET_WAREHOUSE:
      return { ...state, warehouseDetail: payload };
    case GET_ALL_PERMISSIONS:
      return{ ...state, allPermissions: payload}
     
    case GET_ALL:
    return { ...state, allWFDetail: payload };
    // case GET_DATE_ANALYTICS:
    case GET_ALL_ANALYTICS:
      return {...state, allAnalytics:payload};
    // case GET_WAREHOUSE_ANALYTICS:
    //   return { ...state, warehouseAnalytics:payload};
    // case GET_FLOOR_ANALYTICS:
    //   return { ...state, floorAnalytics:payload}
    case SET_FLOOR:
      return { ...state, selectedFloor: payload };
    case GENERATE_QR:
      return{...state, generatedQr:payload};
    case EDIT_COMPANY:
      return { ...state, companies: payload };
    
    //     ...state,
    //     companies: state.companies.filter(({ _id }) => _id !== payload)
    //   };
    // case DELETE_ADMIN:
    //   return {
    //     ...state,
    //     superadmin: state.superadmin.filter(({ _id }) => _id !== payload)
    //   };
    default:
      return state;
  }
};
export default adminReducer;
