import {
    GET_SHIPMENT_USER,
    CREATE_SHIPMENT_USER,
    CHANGE_USER_STATUS,
    GET_DUTY_LOGS
} from "../../constants/ActionTypes";

const intialState = {
    wmsAllUser: {},
    logsData:{}
};


const wmsUserReducer = (state = intialState, { type, payload }) => {
    switch (type) {

        case GET_SHIPMENT_USER:     
            return {
                ...state,
                wmsAllUser: payload
            }
        case GET_DUTY_LOGS:
            return {
                ...state,
                logsData: payload
            }

        default:
            return state;
    }
}
export default wmsUserReducer;