import React,{useEffect, useState} from "react";
import Burger from "./Burger";
import connectLogo from "./../../assets/images/Connect.svg"
import HeaderPic from "./../../assets/images/Header 1.svg"
import dotPic from "./../../assets/images/dot-a.svg"
import backgroundImg from "./../../assets/images/Background image.jpg"
import locationIcon from "./../../assets/images/location.svg"
import connectIcon from "./../../assets/images/Connect.svg"
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function HeadBar() {
    const [accordianActive, setAccordianActive] = useState("first")
    const [scrolled,setScrolled]=useState(false);
    
    // console.log(111);
    const ChangeValueScroll=(e)=>{
    //   const scrollVal=document.body.scrollTop
    let element = e.target
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //   // do something at end of scroll
    // }
      if(element.scrollTop>10){
        // console.log('1',element.scrollTop);
        setScrolled(true)
      }
      else{
        // console.log('2',element.scrollTop);
        setScrolled(false)
      }
    }
    
    // useEffect(()=>{
    //     window.addEventListener("scroll",()=>console.log("changesssssss"))
    // },[])
    return (
        <div className="webpage-wrapper" onScroll={ChangeValueScroll}>
            <div className="main-website" >
                <div className="web-back1">
                    <div className={scrolled ? 'side-margin web-head-nav' : 'side-margin web-head'}>
                        <div className="logo-size"><Link to="/"><img alt="lynkgrid logo" src={require("./../../assets/images/logo-white.png")} style={{ width: '200px' }} /></Link></div>
                        {/* <div className="web-head web-navbar"><b>Home</b><b>About Us</b><b>Contact Us</b></div> */}
                        <Burger />
                    </div>
                    <div className="web-flex side-margin back2-sec top-margin">
                        <div><h5 className="web-spl-txt">WAREHOUSE</h5><h2 className="web-spl-txt2">Management System</h2></div>
                        <div className="pos-rel dot-whole ">
                            <img src={dotPic} alt="Dot picture around" className="pos-abs dot-blc" />
                            <img src={HeaderPic} alt="Connection image" className="pos-rel web-img1" />
                            <img src={dotPic} alt="Dot picture around" className="pos-abs dot-trc" />
                        </div>
                    </div>
                </div>
                <div className="web-back2 web-flex side-margin pos-rel">
                    <div className="dot-whole2 ">
                        <img src={require("./../../assets/images/L1.png")} alt="laptop img" className=" web-img2 " />
                    </div>
                    <div className="web-sec2-txt">
                        <h1 className="web-heading2">Warehouse GRID View</h1>
                        <p className="web-detail1">Warehouse grid view is the most important module of the entire application.</p>
                           <p className="web-detail1"> User will be able to view all available stocks with various filters like product type, material age, damage material on the portal.</p>
                            <p className="web-detail1">Warehouse grid will be reconfigured for all warehouses based on their actual location and area availability.</p>
                            <p className="web-detail1">All material details will be tracked through the portal via click on any location block, below screen will be visible with details.
                        </p>
                    </div>
                </div>
                <div className="web-sec3 pos-rel web-flex3">
                    {/* <img src={require("./../../assets/images/Background image.jpg")} className="back-img pos-rel" /> */}
                    {/* <div style={{backgroundImage:`url(${backgroundImg})`}}></div> */}
                    <div className="web-sec3-container">
                        <h1 className="web-heading3 side-margin">Dashboard</h1>
                        <p className="web-detail2 side-margin">Once data entry is done the entry will be visible will on the dashboard - all shipment details, parcel IDs,
                            barcodes, location will be visible.</p>
                            <p className="web-detail2 side-margin">Shipments can be sorted on basis of routes, movement types etc.</p>
                            <p className="web-detail2 side-margin">Additional “data entry registers” can be created here where admin staff can associate more information with each shipment</p>
                            <p className="web-detail2 side-margin">Shipment data downloads on basis of filters can be generated
                        </p>
                    </div>
                    <div className="sec3-img"><img src={require("./../../assets/images/Laptop 2.png")} className="web-sec3-img"/></div>
                </div>
                <div className="web-back2 web-flex side-margin ">
                    <div className="dot-whole2 pos-rel">
                    {accordianActive=="first" &&<img src={require("./../../assets/images/Area 1.png")} alt="laptop img" className="pos-rel web-img2 " />}
                        {accordianActive=="second" &&<img src={require("./../../assets/images/L3.png")} alt="laptop img" className="pos-rel web-img2 " />}
                        {accordianActive=="third" &&<img src={require("./../../assets/images/RFID 2.png")} alt="laptop img" className="pos-rel web-img2 " />}  
                    </div>
                    <div className="accordian" >
                        <div className="contentBx" onClick={()=>setAccordianActive("first")}>
                            <div className={accordianActive=="first"?"label-web2":"label-web"} >Area mapping option</div>
                            <div className={accordianActive=="first"?"content-web2":"content-web"}><p>Since storage requirements vary from product to product, warehouse mapping studies are necessary to prevent product and monetary loss.
Warehouse mapping entails determining if the area meets the product requirements for the type(s) of goods being stored.</p></div>
                        </div>
                        <div className="contentBx" onClick={()=>setAccordianActive("second")}>
                            <div className={accordianActive=="second"?"label-web2":"label-web"}>Bluetooth based tracking of assets</div>
                            <div className={accordianActive=="second"?"content-web2":"content-web"}><p>A Bluetooth Beacon is a remote gadget that regularly transmits
                                a Bluetooth Low Energy advertising packet. It’s intercepted by a smart device or other
                                Bluetooth digital gadget and used to establish its position in relation to the beacon itself.</p></div>
                        </div>
                        <div className="contentBx" onClick={()=>setAccordianActive("third")}>
                            <div className={accordianActive=="third"?"label-web2":"label-web"}>RFID inventory taking</div>
                            <div className={accordianActive=="third"?"content-web2":"content-web"}><p>RFID provides logistics managers with multiple ways to track and manage products and assets in the supply chain.
                                 RFID tags and scanners can potentially improve product and materials handling inside and outside the warehouse environment, with applications ranging from inventory management to automation</p></div>
                        </div>
                    </div>
                </div>
                <div className="web-back1">
                    <div className="side-margin"><h1 className="web-clients">Lynkit's Client</h1></div>
                    <div className="web-logo-grid ">
                        <div className="web-card"><img src={require("./../../assets/images/samsung.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/konoike_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/bpcl_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/dixon_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/sleepwell_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/bluedart_color copy.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/delhivery_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/landmark_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/flipkart_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/pepfuels_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/ab_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/lg_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/cma_color.jpg")} /></div>
                        <div className="web-card"><img src={require("./../../assets/images/adb_color.jpg")} /></div>
                    </div>
                </div>
                <div className="web-flex gx-justify-content-sm-between">
                    <div className="gx-d-flex gx-align-items-sm-center web-detail-logo">
                        <div><img src={locationIcon} className="icon-height" /></div>
                        <div className="web-contact">
                            <div className="web-heading2">Address</div>
                            <div><p style={{ color: "black" }}>246,Block A,Okhla Industrial Area Phase 1<br/>
                            New Delhi-110020</p></div>
                        </div>
                    </div>
                    <div className="gx-d-flex gx-align-items-sm-center web-detail-logo">
                        <div><img src={connectIcon} className="icon-height" />
                        </div>
                        <div className="web-contact">
                            <div className="web-heading2">Contacts</div>
                            <div><p style={{ color: "black" }}>+9111-4082-4028<br />sales@lynkit.in</p></div>
                        </div>
                    </div>
                    <div className="web-flex-reverse gx-align-items-sm-center">
                        <div style={{ color: "black" }}><p>Copyright ©2019 LynkGrid.<br/>All Rights Reserved</p></div>
                        <div><img src={require("./../../assets/images/SSL.png")} className="last-icon-height" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

