import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    GET_SHIPMENT_USER,
    GET_DUTY_LOGS,
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'
  
    export const getWmsUser= (obj) => {
     
      return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('users/getUsers',obj
        ).then(({data}) => {     
          if (data.success) 
          {  
           dispatch({type: FETCH_SUCCESS});
           dispatch({type: GET_SHIPMENT_USER, payload: data});
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({type: GET_SHIPMENT_USER, payload: {}})
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          console.log("Error****:", error.message);
        });
      }
    };

    
    export const getDutyLogs= (obj) => {
     
      return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('dynamicShipment/getDutyLogs',obj
        ).then(({data}) => {     
          if (data.success) 
          {  
            dispatch({type: FETCH_SUCCESS});
           dispatch({type: GET_DUTY_LOGS, payload: data});
          } 
          else 
          {
            let item={...data,dutyLogs:[],count:0}
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({type: GET_DUTY_LOGS, payload: item})
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          console.log("Error****:", error.message);
        });
      }
    };

    export const createShipmentUser = (obj) => {

      return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('users/createUser', obj
        ).then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: SHOW_MESSAGE, payload: data.message });
          }
          else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
        }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          console.log("Error****:", error.message);
        });
      }
    };

    export const changeStatusUser = (obj) => {

      return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('users/changeStatusUser', obj
        ).then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: SHOW_MESSAGE, payload: data.message });
          }
          else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
        }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          console.log("Error****:", error.message);
        });
      }
    };

    export const changePassword = (obj) => {

      return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('users/changePassword', obj
        ).then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: SHOW_MESSAGE, payload: data.message });
          }
          else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
        }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          console.log("Error****:", error.message);
        });
      }
    };
    export const deleteWmsUser = (obj) => {

      return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/users/deleteUser', obj
        ).then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: SHOW_MESSAGE, payload: data.message })
          }
          else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
        }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          console.log("Error****:", error.message);
        });
      }
    };
    export const editWmsUser = (obj) => {

      return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/users/editUser', obj
        ).then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: SHOW_MESSAGE, payload: data.message })
          }
          else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
        }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          console.log("Error****:", error.message);
        });
      }
    };