import React, { useState, useEffect } from 'react'
import { createBrowserHistory } from "history";
import { Card, Col, Row } from 'antd';
import { Link, Redirect } from 'react-router-dom';

const history =createBrowserHistory()

const WmsUsers = () => {

    const iconFontSize = 'gx-fs-2xl '
    const iconColor = 'gx-text-white'
 
    const [cardData, setCardData] = useState([])
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'));
        if(userData.permissions.createUsers || userData.permissions.viewUsers){
            let tempData = [...cardData];
            tempData.push({
                key: '1',
                icon: 'fa fa-home',
                background: 'gx-bg-teal',
                link: '/home',
                title: 'Home'
    
            })
           if(userData){
                if (userData.permissions.createUsers) {
                    tempData.push({
                        key: '2',
                        icon: 'fa fa-user-plus',
                        background: 'gx-bg-indigo',
                        link: '/createUser',
                        title: 'Create Users'
    
                    })
    
                }
                if (userData.permissions.viewUsers) {
                    tempData.push({
                        key: '3',
                        icon: 'fa fa-users',
                        background: 'gx-bg-amber',
                        link: '/viewUsers',
                        title: 'View Users'
    
                    })
                }
                if (userData.permissions.viewDutyLogs) {
                    tempData.push({
                        key: '4',
                        icon: 'fa fa-black-tie',
                        background: 'gx-bg-sepia',
                        link: '/viewDutyLogs',
                        title: 'Duty Logs'
    
                    })
                }
           }
            setCardData(tempData)
        }
        else{
            history.push('/home')
        }

        
    }, [])

    return (


        <div className='gx-mr-sm-5 gx-ml-sm-5 gx-ml-sm-2 gx-mt-2' data-testid="user-dashboard">
            <Row gutter={[{ xs: 32, sm: 28, md: 26, lg: 24 }, 17]} className="row">

                {cardData && cardData.map((ele, index) => {
                    return (
                        <Col className="column" flex="1 0 25%" span={6} key={index} >
                            <Link to={ele.link} className="gutter-box" data-testid={ele.link}>
                                <Card
                                    style={{
                                        width: '100%',
                                        height: 140,
                                        margin: 0,
                                    }}
                                    className="gx-card gx-hvr-float-shadow gx-p-1"
                                    bordered

                                >
                                    <div className='gx-d-flex gx-justify-content-center gx-align-items-center gx-flex-column gx-mt-1'>
                                        <div className={ele.background + " " + 'gx-shadow' + " " + 'gx-d-flex gx-justify-content-center gx-align-items-center gx-flex-column'} style={styles.divWidth}>
                                            <i className={ele.icon + " " + iconFontSize + " " + iconColor} aria-hidden="true"></i>
                                        </div>
                                        <div className='gx-mt-3 '><h4 className='gx-font-family gx-font-weight-one gx-text-center'>{ele.title}</h4></div>
                                    </div>

                                </Card>
                            </Link>
                        </Col>

                    )
                })}

            </Row>


        </div>
    )
}


const styles = {
    divWidth: {
        width: 55,
        height: 45,
        borderRadius: '.2rem'
    }
}

export default WmsUsers;