import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE, 
    GET_MAPPED_AREA,
    GET_MAPPED_ANALYTICS
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'

  export const getMappedArea = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      
      await axios.post('area/getAreaMapping',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          // dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({type: GET_MAPPED_AREA, payload: data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
          dispatch({type: GET_MAPPED_AREA, payload: {}});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  export const getMappedAnalytics = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('area/getAreaMappingAnalytics',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          // dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({type: GET_MAPPED_ANALYTICS, payload: data?data:{}});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
          dispatch({type: GET_MAPPED_ANALYTICS, payload: []});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const editMappedArea = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('area/updateAreaMapping',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
         
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const addMappedArea = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('area/areaMapping',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };