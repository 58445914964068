import React,{useState} from 'react'
import Burger from './Burger'
import locationIcon from "./../../assets/images/location.svg"
import connectIcon from "./../../assets/images/Connect.svg"
import { Link } from "react-router-dom";


export default function About() {
    const [scrolled,setScrolled]=useState(false);
    
    const ChangeValueScroll=(e)=>{
    //   const scrollVal=document.body.scrollTop
    let element = e.target
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //   // do something at end of scroll
    // }
      if(element.scrollTop>10){
        // console.log('1',element.scrollTop);
        setScrolled(true)
      }
      else{
        // console.log('2',element.scrollTop);
        setScrolled(false)
      }
    }
    return (
        <div className='webpage-wrapper' onScroll={ChangeValueScroll}>
            <div className='contact-sec1'>
            <div className={scrolled ? 'side-margin web-head-nav' : 'side-margin web-head'}>
                    <div className="logo-size"><Link to='/'><img alt="" src={require("./../../assets/images/logo-white.png")} style={{ width: '200px' }} /></Link></div>
                    {/* <div className="web-head web-navbar"><b>Home</b><b>About Us</b><b>Contact Us</b></div> */}
                    <Burger />
                </div>
                <div className='contact-heading'><img src={require("./../../assets/images/Group 19.png")} className='img-over-half side-margin' /></div>
            </div>
            <div className='web-detail1 aboutUs-txt side-margin'>
                <p>We are a software company, specializing in providing fully stacked B2B supply chain management and optimisation solutions.
                    We specialize in block chain applications, IoT based trace and track solutions, process and document automation, and
                    e-governance solutions, which have been deployed in a diverse set of industries such as logistics & transportation,
                    manufacturing and e-commerce.
                </p>
                <p>Lynkit’s applications cover the entire spectrum of the supply chain. We help to track, locate, analyse and ease the movement
                    of your goods from one shelf to another in your warehouse, from factory to the port all the way to the receiver in another country.
                    Our motto is “orchestrating efficiency”, which is what we set out to do in each of our projects by “lynking” every stakeholder,
                    process and action into a seamless and integrated process flow.
                </p>
                <p>We are headquartered in New Delhi with 45 full time employees engaged in software development and support our clients
                    through our 24x7 control room. Our technology team is led by highly experienced professionals with rich experience in global
                    companies like Samsung, S.T. Microelectronics, AECOM (USA).
                </p>
            </div>
            {/* <h1 className='web-spl-txt side-margin'>ABOUT US</h1> */}
            <div className='web-back1 gx-d-flex gx-flex-column gx-justify-content-center'>
                <div className='gx-d-flex gx-justify-content-between gx-align-items-center side-margin'>
                    <div><h1 className="web-clients about-basis-4">Our Partners</h1></div>
                    <div className='gx-d-flex gx-justify-content-between about-basis-6'>
                        <div className='contact-clients'><img src={require('./../../assets/images/Lynkit.io.png')}/></div>
                        <div className='contact-clients'><img src={require('./../../assets/images/Lynkgrid.png')}/></div>
                        <div className='contact-clients'><img src={require('./../../assets/images/Lynktrac.png')}/></div>
                    </div>
                </div>
                <div className='about-logo-grid about-center'>
                    <div className='about-card'><img src={require('../../assets/images/FICCI-Black-Logo.png')} /></div>
                    <div className='about-card'><img src={require('../../assets/images/Incheon Port-Logo.png')} /></div>
                    <div className='about-card'><img src={require('../../assets/images/Cupia-Logo.jpg')} /></div>
                    <div className='about-card'><img src={require('../../assets/images/KL Net-Logo.png')} /></div>
                    <div className='about-card'><img src={require('../../assets/images/Bita Blockchain-Logo.png')} /></div>
                </div>
            </div>
            <div className="web-flex gx-justify-content-sm-between">
                <div className="gx-d-flex gx-align-items-sm-center web-detail-logo">
                    <div><img src={locationIcon} className="icon-height" /></div>
                    <div className="web-contact">
                        <div className="web-heading2">Address</div>
                        <div className='aboutUs-txt-foot'><p style={{ color: "black" }}>246,Block A,Okhla Industrial Area Phase 1<br />
                            New Delhi-110020</p></div>
                    </div>
                </div>
                <div className="gx-d-flex gx-align-items-sm-center web-detail-logo">
                    <div><img src={connectIcon} className="icon-height" />
                    </div>
                    <div className="web-contact">
                        <div className="web-heading2">Contacts</div>
                        <div className='aboutUs-txt-foot'><p style={{ color: "black" }}>+9111-4082-4028<br />sales@lynkgrid.com</p></div>
                    </div>
                </div>
                <div className="web-flex-reverse gx-align-items-sm-center">
                    <div style={{ color: "black" }} className='aboutUs-txt-foot'><p>Copyright ©2019 LynkGrid.<br />All Rights Reserved</p></div>
                    <div><img src={require("./../../assets/images/SSL.png")} className="last-icon-height"/></div>
                </div>
            </div>

        </div>
    )
}