import React,{useState} from 'react'
import Burger from './Burger'
import {Button, Form, Input} from "antd";
import locationIcon from "./../../assets/images/location.svg"
import connectIcon from "./../../assets/images/Connect.svg"
import { Link } from "react-router-dom";


const {TextArea}=Input
export default function Contact() {
    const [scrolled,setScrolled]=useState(false);
    
    const ChangeValueScroll=(e)=>{
    //   const scrollVal=document.body.scrollTop
    let element = e.target
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    //   // do something at end of scroll
    // }
      if(element.scrollTop>10){
        // console.log('1',element.scrollTop);
        setScrolled(true)
      }
      else{
        // console.log('2',element.scrollTop);
        setScrolled(false)
      }
    }
    return (
        <div className='webpage-wrapper' onScroll={ChangeValueScroll}>
            <div className='contact-sec1'>
            <div className={scrolled ? 'side-margin web-head-nav' : 'side-margin web-head'}>
                <div className="logo-size"><Link to='/'><img alt="" src={require("./../../assets/images/logo-white.png")} style={{ width: '200px' }} /></Link></div>
                {/* <div className="web-head web-navbar"><b>Home</b><b>About Us</b><b>Contact Us</b></div> */}
                <Burger />
            </div>
            <div className='contact-heading'><img src={require("./../../assets/images/Group 13.png")} className='img-over-half side-margin'/></div>
        </div>
            {/* <h1 className='web-spl-txt side-margin'>CONTACT US</h1> */}
            <div className='contact-mid side-margin'>
                <div className='gx-flex-sm-column contact-left'>
                    <Input placeholder='Full Name' className='contact-inpts'></Input>
                    <Input placeholder='Mobile Number' className='contact-inpts'></Input>
                    <Input placeholder='Email Address' className='contact-inpts'></Input>
                    <TextArea placeholder='Message'rows={4} className='contact-inpts'></TextArea>
                    <div className='input-btn'><Button className='contact-button'>Contact Us</Button></div>
                </div>
                <div className='contact-right'>
                    <div className='gx-d-flex'>
                        <div className='contact-img-div'><img src={locationIcon} className="contact-logo" /></div>
                        <div className='gx-flex-sm-column'><div className="web-heading2-w">Address</div>
                            <div><p>246, Block A, Okhla Industrual Area Phase 1<br />New Delhi: 110020</p></div></div>
                    </div>
                    <div className='gx-d-flex'>
                        <div className='contact-img-div'><img src={connectIcon} className="contact-logo" /></div>
                        <div className='gx-flex-sm-column'>
                            <div className='web-heading2-w'>Contacts</div>
                            <div><p>+9111-4082-4028<br />sales@lynkgrid.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div >
                <div className='contact-foot'>
                    <div><img src={require("./../../assets/images/SSL.png")} /></div>
                    <div style={{color:"black"}}><p>Copyright ©2019 LynkGrid.All Rights Reserved</p></div>
                </div>
            </div>
        </div>
    )
}