import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    GET_DASHBOARD
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'

  export const getDashboardList = (obj) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await axios.post('trip/list',obj
      ).then(({ data }) => {
        console.log('zone',data);
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: GET_DASHBOARD, payload: data.data });
        }
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_DASHBOARD, payload: [] });
        }
      }).catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
    }
  };