import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE, 
    GET_DAMAGE_COLLECTION_DATA
    
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'

  export const getDamageCollection = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('/damageCollection/getDamageArea',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
         dispatch({type: GET_DAMAGE_COLLECTION_DATA, payload: data.data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

 

  export const addDamageCollection = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('damageCollection/addDamageArea',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };