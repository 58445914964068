import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_COMPONENT_LIST,
  GET_REGEX_LIST,
  GET_API_LIST,
  GET_TEMPLATE_LIST,
  GET_TEMPLATE_DETAIL,
  GET_TEMPLATE_DATA,
  GET_TEMPLATE_DATA_BY_SHIPMENT,
  VIEW_FILLED_LOCATION_BY_SHIPMENT,
  GET_TEMPLATE_DATA_BY_LOCATION,
  GET_GRID_BLOCK_DATA_BY_LOCATION,
  GET_SEAL_DATA,
  GET_ALL_JOB,
  GET_PACKAGE_DETAILS,
  GET_FILE_URL,
  GET_SKU_HEADER,
  GET_TEMPLATE_HEADER,
  VALIDATE_PRODUCT_MASTER_DESCRIPTION,
  GET_PRODUCT_MASTER,
  GET_PACKAGE_DETAIL_TEMPLATE,
  GET_FREE_TEMPLATE,
  GET_JOB_DETAIL
} from "../../constants/ActionTypes";
import axios from "../../util/Api";
//import axios from 'axios';

export const getComponentList = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getComponentList", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_COMPONENT_LIST, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_COMPONENT_LIST, payload: [] });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const getRegexList = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getRegexList", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          //dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_REGEX_LIST, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getApiList = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getApiList", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          //dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_API_LIST, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const saveTemplate = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/saveTemplate", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
           dispatch({type: SHOW_MESSAGE, payload: data.message});
          // dispatch({type: SAVE_TEMPLATE, payload: data.data});
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getTemplateList = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getTemplateList", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            if (obj.fetch_type && obj.fetch_type == "freeTemplate") {
              dispatch({ type: GET_FREE_TEMPLATE, payload: data });
            }
            else if (obj.fetch_type && obj.fetch_type == "packageDetail") {
              
              dispatch({ type: GET_PACKAGE_DETAIL_TEMPLATE, payload: data });
            }
            else {
              dispatch({ type: GET_TEMPLATE_LIST, payload: data});
            }
        } 
        else {
            if (obj.fetch_type && obj.fetch_type == "freeTemplate") {
              dispatch({ type: GET_FREE_TEMPLATE, payload: {} });
            }
            else if (obj.fetch_type && obj.fetch_type == "packageDetail") {
              dispatch({ type: GET_PACKAGE_DETAIL_TEMPLATE, payload: {} });
            }
            else {
              dispatch({ type: GET_TEMPLATE_LIST, payload: {}});
            }
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getTemplateDetail = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getTemplateDetail", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_TEMPLATE_DETAIL, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_TEMPLATE_DETAIL, payload: {} });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getTemplateHeader = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getTemplateHeader", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_TEMPLATE_HEADER, payload: data});
        } else {
          dispatch({ type: GET_TEMPLATE_HEADER, payload: {} });
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const saveTemplateData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/saveTemplateData", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          //console.log('save-----');
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const saveBulkData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/saveBulkData", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } 
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const editBulkData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/editBulkData", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } 
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getTemplateData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getTemplateData", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          //  dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_TEMPLATE_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
         
          let item={...data,data:[]}
          dispatch({ type: GET_TEMPLATE_DATA, payload: item });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};


export const changeTemplateStatus = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/changeTemplateStatus", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const editTemplateInfo = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/editLTemplateInfo", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const editTemplateDetails = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/editTemplateDetails", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const deleteTemplateData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/deleteTemplateData", obj)
      .then(({ data }) => {
        if (data.success || !data.error) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getTemplateDataByShipment = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("shipmentByDynamicForm/getTemplateDataByShipment", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          //dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_TEMPLATE_DATA_BY_SHIPMENT, payload: data.shipmentData[0]  });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_TEMPLATE_DATA_BY_SHIPMENT, payload: {}  });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const getTemplateDataByLocation = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("shipmentByDynamicForm/getTemplateDataByShipment", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          //dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({ type: GET_TEMPLATE_DATA_BY_LOCATION, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getGridBlockData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("dynamicShipment/gridBlockData", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: GET_GRID_BLOCK_DATA_BY_LOCATION, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          let item={...data,data:[],count:0}
          dispatch({ type: GET_GRID_BLOCK_DATA_BY_LOCATION, payload: item });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const fillShipmentByLocation = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("shipmentByDynamicForm/fillLocationByShipment", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          //dispatch({type: FILL_SHIPMENT_BY_LOCATION, payload: data});
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const viewFilledLocationByShipment = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("shipmentByDynamicForm/viewFilledLocationsByShipmentV2", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: VIEW_FILLED_LOCATION_BY_SHIPMENT, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const gateOutShipment = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("shipmentByDynamicForm/gateOutV2", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getSeal = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/shipmentByDynamicForm/getSeal", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: GET_SEAL_DATA, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const mapSeal = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/shipmentByDynamicForm/mapSeal", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const dellocateSeal = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/shipmentByDynamicForm/dellocateSeal", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const multiMapSeal = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/shipmentByDynamicForm/multiMapSeal", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};


export const changeShipmentLocation = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/changeShipmentLocation", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const createJob = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/createJob", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const endJobs = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/endJobs", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const getJob = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/getJobs", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: GET_ALL_JOB, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_ALL_JOB, payload: {} });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const getJobDetail = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/getJobDetail", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: GET_JOB_DETAIL, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_JOB_DETAIL, payload: {} });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const reAllocateJob = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/reAllocateJob", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const closeJobs = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/closeJobs", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getPackageDetails = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: GET_PACKAGE_DETAILS, payload:{}});
    // dispatch({ type: FETCH_ERROR, payload: "" });
    await axios
      .post("/dynamicShipment/getPackageDetails", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: GET_PACKAGE_DETAILS, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_PACKAGE_DETAILS, payload: {}});
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const uploadSealExcel = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/uploadSealExcel", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getProductMaster = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/getProductMasterData", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: GET_PRODUCT_MASTER, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_PRODUCT_MASTER, payload: {...data,data:[]} });
        }
      })
     
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const saveProductMaster = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/saveProductMasterData", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
     
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const editProductMasterData = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/editProductMasterData", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
     
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const validateMasterDescription = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/validateProductDescription", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: VALIDATE_PRODUCT_MASTER_DESCRIPTION, payload: data});
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: VALIDATE_PRODUCT_MASTER_DESCRIPTION, payload: {}});
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const gateOutViaScan = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/gateOutViaScan", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } 
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
export const getFileURL = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicShipment/getFileURL", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: GET_FILE_URL, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const getHeaderWithSKUId = obj => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/dynamicForm/getHeaderWithSKUId", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: GET_SKU_HEADER, payload: data});
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};
