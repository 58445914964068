import {
    GET_FILLED_LOCATION,
    GET_ALL_SHIPMENT_BY_LOCATION,
    GET_GRID_CELL_DETAIL,
    GET_GRID_RACK_DETAIL,
    GET_GRID_VIEW,
    GET_GRID_DATA,
    GET_RACK_DATA,
    GET_GRID_ALL_DETAIL,
    GET_GRID_CELL_DIMENTION,
    GET_GRID_RACK_DIMENTION,
    GET_GRID_ALL_DIMENTION
} from "../../constants/ActionTypes";

const intialState = {
    filledLocation: {},
    shipmentByLocation: [],
    gridView: {},
    gridData: [],
    rackData: [],
    gridCellDetails: [],
    gridRackDetails: [],
    gridALLDetails: [],
    gridCellDimentions: [],
    gridRackDimentions: [],
    gridALLDimentions: []
};


const warehouseReducer = (state = intialState, { type, payload }) => {
    switch (type) {

        case GET_FILLED_LOCATION:

            return {
                ...state,
                filledLocation: payload
            }
        case GET_ALL_SHIPMENT_BY_LOCATION:

            return {
                ...state,
                shipmentByLocation: payload.shipments
            }
        case GET_GRID_VIEW:

            return {
                ...state,
                gridView: payload
            }
        case GET_GRID_DATA:

            return {
                ...state,
                gridData: payload
            }
        case GET_GRID_CELL_DETAIL:

            return {
                ...state,
                gridCellDetails: payload
            }
        case GET_GRID_RACK_DETAIL:

            return {
                ...state,
                gridRackDetails: payload
            }
        case GET_GRID_ALL_DETAIL:

            return {
                ...state,
                gridALLDetails: payload
            }
        case GET_GRID_CELL_DIMENTION:

            return {
                ...state,
                gridCellDimentions: payload
            }
        case GET_GRID_RACK_DIMENTION:

            return {
                ...state,
                gridRackDimentions: payload
            }
        case GET_GRID_ALL_DIMENTION:

            return {
                ...state,
                gridALLDimentions: payload
            }
        case GET_RACK_DATA:

            return {
                ...state,
                rackData: payload
            }
        default:
            return state;
    }
}
export default warehouseReducer;