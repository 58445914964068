
import React, { Component } from "react";
import { Layout } from "antd";
import Topbar from "../Topbar/index";

import App from "routes/index";
import { connect } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";

const { Content } = Layout;

export class MainApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base: "",
      user: {}
    };
  }

  getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return ""
    }
  };
  getNavStyles = () => {
    return <Topbar user={this.state.user || {}}/>;

  };

  componentDidMount = () => {
    let userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      this.setState({
        ...this.state,
        base: userData.base,
        user: userData
      })
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.user !== state.user) {
      let userData = JSON.parse(localStorage.getItem('user'));
      if (userData) {
        return {
          base: userData.base,
          user: userData
        }
      }
    }
    else {
      return {}
    }
  }


  render() {
    const { match, navStyle, authUser } = this.props;
    return (
      <Layout className="gx-app-layout">

        <Layout
          className={
            (this.state.base != 'Visitor')
              ? (window.location.pathname == "/home" ||
                window.location.pathname == "/user" ||
                window.location.pathname == "/indoorTracking" ||
                window.location.pathname == "/templateDashboard" ||
                window.location.pathname == "/inventory" ||
                window.location.pathname == "/titles" ||
                window.location.pathname == "/jobDashboard" ||
                window.location.pathname == "/productMaster" ||
                window.location.pathname == "/packageDetail" ||
                window.location.pathname == "/WMSReport" ||
                window.location.pathname == "/WMSReport/inventoryReport" ||
                window.location.pathname == "/reports")
                ? 'home'
                : ''
              : ''
          }
        >
          {this.getNavStyles(navStyle)}
          <Content className={`gx-layout-content ${this.getContainerClass(navStyle)} `}>
            <App match={match} user={this.state.user} />
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { width, navStyle } = settings;
  const { authUser } = auth;

  return { width, navStyle, authUser }
};

export default connect(mapStateToProps)(MainApp);

