import {
    GET_SEARCH_TAG
} from "../../constants/ActionTypes";

const intialState = {
    searchTagData:{},
};


const searchTagReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_SEARCH_TAG:
            return { ...state, searchTagData: payload }       
        default:
            return state;
    }
}
export default searchTagReducer;