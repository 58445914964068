import {
    GET_SHIPMENT_REPORT,
    GET_WMS_REPORT,
    GET_WMS_REPORT_EXCEL,
    GET_WMS_REPORT_PDF
} from "../../constants/ActionTypes";

const intialState = {
   
    shipmentReport:{},
    reportViwerData:{},
    reportExcelData:{},
    reportPDFData:{},
};


const reportReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_SHIPMENT_REPORT:
            return { ...state, shipmentReport: payload }    
        case GET_WMS_REPORT:
            return { ...state, reportViwerData: payload }    
        case GET_WMS_REPORT_EXCEL:
            return { ...state, reportExcelData: payload }    
        case GET_WMS_REPORT_PDF:
            return { ...state, reportPDFData: payload }    
        default:
            return state;
    }
}
export default reportReducer;