import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    ADD_ASSET,
    ADD_GATEWAY,
    GET_GATEWAY,
    GET_ASSET,
    DELETE_GATEWAY,
    GET_TRACK_BY_DEVICE
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'

  export const addGateway = (obj) => {
    return async (dispatch) => {
      
      dispatch({type: FETCH_START});
      await axios.post('gateway/addGateway',obj
      ).then(({data}) => {
       
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
         
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  export const addAsset = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('asset/addAsset',obj
      ).then(({data}) => {
        
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  export const getGateway = (obj) => {
    return async (dispatch) => {
      
      dispatch({type: FETCH_START});
      await axios.post('gateway/getGateways',obj
      ).then(({data}) => {
        
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: GET_GATEWAY, payload: data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  export const getAsset = (obj) => {
    return async (dispatch) => {
      dispatch({type: FETCH_START});
      await axios.post('asset/getAssets',obj
      ).then(({data}) => {
        
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: GET_ASSET, payload: data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const deleteAsset = (obj) => {
    return async (dispatch) => {
      dispatch({type: FETCH_START});
      await axios.post('asset/deleteAsset',obj
      ).then(({data}) => {
       
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
         
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const deleteGateway = (obj) => {
    //console.log(obj,'dg');
    return async (dispatch) => {
      dispatch({type: FETCH_START});
      await axios.post('gateway/deleteGatway',obj
      ).then(({data}) => {
        console.log('zone',data);
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          //dispatch({type: DELETE_GATEWAY, payload: data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const getTrackByAsset= (obj) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
    //console.log(obj,'track');
      axios.post('track/getTrackByAsset',obj
      ).then(({data}) => {
         console.log(data,'track');
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
         dispatch({type: GET_TRACK_BY_DEVICE, payload: data.data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };