import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Input,
    Select,
    Card,
    InputNumber,
    notification,
    message
} from 'antd';
import { addGridData } from "../../../appRedux/actions";


const displayMessage=message

export default function Gridmade() {
    const { message, error } = useSelector((state) => state.commonData);


    const [tempArr, setTempArr] = useState([])
    const [selectedCells, setSelectedCells] = useState([])
    const [copiedCellId, setCopiedCellId] = useState([])
    const [valid, setValid] = useState(false)
    const [inputs, setInputs] = useState({ typeDropdown: "block", unitDropdown: "feet" });
    const [task, setTask] = useState(false)
    const [grid, setGrid] = useState({})
    const [isCopied, setIsCopied] = useState(false)
    const floorData = useSelector(state => state.superadmin.selectedFloor)
    const dispatch = useDispatch();
    const { Option } = Select

    const makeGrid = () => {
        let rows = grid.rows;
        let columns = grid.columns;
        let newArry = []

        if (rows && columns) {
            for (let i = 0; i < rows; i++) {
                for (let j = 0; j < columns; j++) {
                    newArry.push({
                        "cellId": new Date().getTime() + "" + (i * rows) + "" + j + "",
                        "name": "",
                        // "name" : ArrChar[i]+""+(j+1),
                        "base": floorData.base,
                        "warehouseId": floorData.warehouseId,
                        "type": "NA",
                        "isRackExist": false,
                        "rowIndex": i,
                        "colIndex": j,
                        "disable": false,//(i>10 && j>1)?true:
                        "floorId": floorData.floorId,
                        "partial": true,
                        "status": "available",
                        // "selected": false,
                        "fontColor": "",
                        "bgColor": "",
                        // "isCopied": false
                    })
                }
            }
        }
        setTempArr(newArry)
        setTask(true);
    }


    const updateGrid = (cellId) => {

        let cellSelect = []
        let gridBlock = tempArr.find(el => el.cellId === cellId)

        // gridBlock.selected = !gridBlock.selected;

        if (gridBlock) {

            let valid = true
            if (gridBlock.isSelected) {

                valid = true
                let tempIndex = selectedCells.findIndex(el => el.cellId == cellId)
                cellSelect.splice(tempIndex, 1)
                setSelectedCells(cellSelect)
            }
            else {
                // valid = checkSelectionValidity(gridBlock)
                if (valid) {

                    cellSelect.push(gridBlock)
                    setSelectedCells(cellSelect)


                }
            }
            if (valid) {
                gridBlock.isSelected = gridBlock.isSelected ? false : true
                setTempArr([...tempArr])
            }
            // else{
            // }
        }
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))

    }
    const handleRow = (value) => {
        setGrid(values => ({ ...values, "rows": value }))
    }
    const handleColumn = (value) => {

        setGrid(values => ({ ...values, "columns": value }))
    }
    function checkSelectionValidity(gridBlock) {
        if (selectedCells.length == 0) {
            return true
        }
        else {
            return selectedCells.some(el => {
                if (el.rowIndex == gridBlock.rowIndex && (el.colIndex + 1 == gridBlock.colIndex || el.colIndex - 1 == gridBlock.colIndex)) {
                    return true
                }
                if (el.colIndex == gridBlock.colIndex && (el.rowIndex + 1 == gridBlock.rowIndex || el.rowIndex - 1 == gridBlock.rowIndex)) {
                    return true
                }
                return false
            })
        }
    }


    function getBlockByIndex(rowIndex, colIndex) {
        return (tempArr.find(el => el.rowIndex == rowIndex && el.colIndex == colIndex))
    }

    function submitHandle() {
        let type = inputs.typeDropdown;
        // let type = "merge"
        let mergeRowsInput = inputs.rowsCount
        let mergeColumnInput = inputs.columnCount
        let labelInput = inputs.labelInput
        let bgColor = inputs.bgColor || ""
        let fontColor = inputs.fontColor || ""
        let length = inputs.length ?inputs.length + (inputs.unitDropdown ? inputs.unitDropdown : "feet"): ""
        let width = inputs.width ? inputs.width + (inputs.unitDropdown ? inputs.unitDropdown : "feet") : ""
        let valid = false
        let newCells = tempArr.filter(arr => arr.isSelected === true)
        let obj = getBoundaryCordinates(newCells)
        if (newCells.length) {

            valid = checkLayout(obj, newCells)
        }

        if (valid) {

            let mergeRows = Number(mergeRowsInput) || (obj.endRow - obj.startRow + 1) || 0
            let mergeColumn = Number(mergeColumnInput) || (obj.endCol - obj.startCol + 1) || 0

            // let mergeRows = newCells.length === 1 ? Number(mergeRowsInput) : (obj.endRow - obj.startRow + 1)
            // let mergeColumn = newCells.length === 1 ? Number(mergeColumnInput) : (obj.endCol - obj.startCol + 1)

            if ((mergeColumn || mergeRows) && type) {
                // if ((mergeColumn > 1 || mergeRows > 1) && type) {
                let gridBlock = tempArr.find(el => el.rowIndex == obj.startRow && el.colIndex == obj.startCol)
                gridBlock.type = type
                gridBlock.name = labelInput ? labelInput.toUpperCase() : ""
                gridBlock.length = length
                gridBlock.width = width
                if (mergeRows > 1) {
                    gridBlock.mergeRows = mergeRows

                }
                if (mergeColumn > 1) {
                    gridBlock.mergeColumn = mergeColumn

                }
                gridBlock.bgColor = bgColor;
                gridBlock.fontColor = fontColor;
                for (let i = 0; i < Number(mergeRows); i++) {
                    for (let j = 0; j < Number(mergeColumn); j++) {
                        let index = tempArr.findIndex(el => el.colIndex == gridBlock.colIndex + j && el.rowIndex == gridBlock.rowIndex + i && el.cellId != gridBlock.cellId)
                        if (index > -1) {
                            tempArr.splice(index, 1)
                        }
                    }
                }
                newCells = []
                setSelectedCells([])
                delete gridBlock.isSelected
                setTempArr([...tempArr])
                setInputs(({ typeDropdown: "block" }))

            }
        }
        else {
            displayMessage.error("Not valid selection")
        }

    }

    function checkLayout(obj, arr) {

        let valid = true
        for (let i = obj.startRow; i <= obj.endRow; i++) {
            for (let j = obj.startCol; j <= obj.endCol; j++) {
                let tempIndex = arr.findIndex(el => el.rowIndex == i && el.colIndex == j)

                if (tempIndex == -1) {
                    valid = false

                    break;
                }
            }
            if (valid == false) {
                break;
            }
        }
        return valid
    }
    function copyLayout(cellId) {
        let gridBlock = tempArr.find(el => el.cellId == cellId)

        gridBlock.isSelected = false;
        if (gridBlock.mergeColumn || gridBlock.mergeRows) {
            setIsCopied(!isCopied)
            setCopiedCellId(cellId)
            // setTempArr([...tempArr])
        }
    }
    function unSelect(cellId) {
        // console.log("1 ", tempArr);
        let gridBlock = tempArr.find(el => el.cellId == cellId)
        // if (gridBlock.mergeRows) {
        //     delete gridBlock.mergeRows;
        // }
        // if (gridBlock.mergeColumn) {
        //     delete gridBlock.mergeColumn;
        // }
        // console.log("2", tempArr);
        // setTempArr([...gridBlock])
        let index = tempArr.findIndex(block => block.cellId == cellId)

        let unselected = []
        let rows = gridBlock.mergeRows || 1
        let cols = gridBlock.mergeColumn || 1

        for (let i = 0; i < rows; i++) {

            for (let j = 0; j < cols; j++) {

                unselected.push({
                    "cellId": new Date().getTime() + "" + (i * rows) + "" + j + "",
                    "name": "",
                    // "name" : ArrChar[i]+""+(j+1),
                    "base": floorData.base,
                    "warehouseId": floorData.warehouseId,
                    "type": "NA",
                    "isRackExist": false,
                    "rowIndex": gridBlock.rowIndex + i,
                    "colIndex": gridBlock.colIndex + j,
                    "disable": false,//(i>10 && j>1)?true:
                    "floorId": floorData.floorId,
                    "partial": true,
                    "status": "available",
                    // "selected": false,
                    "fontColor": "",
                    "bgColor": "",
                    // "isCopied": false
                })
            }
        }
        let local = [...tempArr]

        local.splice(index, 1, ...unselected)

        setTempArr(local)
    }

    function pasteLayout(cellId) {
        let gridBlock = tempArr.find(el => el.cellId == cellId)

        if (isCopied && copiedCellId) {
            let copiedCell = tempArr.find(el => el.cellId == copiedCellId)

            let pasteCell = [];
            // selectedCells = []
            let copiedRows = copiedCell.mergeRows || 1
            let copiedCells = copiedCell.mergeColumn || 1
            for (let i = 0; i < copiedRows; i++) {
                for (let j = 0; j < copiedCells; j++) {
                    let tempCell = tempArr.find(el => el.rowIndex == gridBlock.rowIndex + i && el.colIndex == gridBlock.colIndex + j)

                    if (tempCell && (!tempCell.mergeRows || tempCell.mergeRows == 1) && (!tempCell.mergeColumn || tempCell.mergeColumn == 1)) {

                        tempCell.isSelected = true
                        pasteCell.push(tempCell)
                        setSelectedCells([...pasteCell])
                        setIsCopied(!isCopied)
                    }
                }
            }
            gridBlock.isCopied = !gridBlock.isCopied
            setCopiedCellId([])

        }

    }
    function getBoundaryCordinates(arr) {

        let obj = {
            startRow: '',
            startCol: '',
            endRow: '',
            endCol: ""
        }
        if (arr.length) {
            let rowsArr = arr.map(el => el.rowIndex)
            let colsArr = arr.map(el => el.colIndex)
            obj.startRow = Math.min(...rowsArr)
            obj.endRow = Math.max(...rowsArr)
            obj.startCol = Math.min(...colsArr)
            obj.endCol = Math.max(...colsArr)
        }

        return obj

    }
    const selectAfter = (
        <Select defaultValue="feet" className="select-after">
            <Option value="feet">feet</Option>
            <Option value="meters">meters</Option>
            <Option value="inch">Inches</Option>
        </Select>
    );
    const printArray = () => {

        let maxColumn = 0;
        let maxRow = 0;
        for (let i = 0; i < tempArr.length; i++) {
            for (let j = 0; j < grid.columns; j++) {
                if (tempArr[i].colIndex === j && tempArr[i].type != "NA" && j + (tempArr[i].mergeColumn || 1) > maxColumn) {
                    maxColumn = j + (tempArr[i].mergeColumn || 1);
                }
            }
            for (let r = 0; r < grid.rows; r++) {
                if (tempArr[i].rowIndex === r && tempArr[i].type != "NA" && r + (tempArr[i].mergeRows || 1) > maxRow) {
                    maxRow = r + (tempArr[i].mergeRows || 1);

                }
            }
        }


        let sendArr = tempArr.filter(arr => arr.type != "NA")
        // checkGrids(maxColumn, maxRow)
        let arrfi = tempArr.find(arr => arr.rowIndex < maxRow && arr.colIndex < maxColumn && arr.type == "NA")
        // dispatch(

        if (arrfi) {
            displayMessage.error("please complete your grid ");
            // setValid(false)
        }
        else if (!arrfi) {
           
            let GridData = {
                "floorId": floorData.floorId,
                "warehouseId": floorData.warehouseId,
                "row": maxRow,
                "col": maxColumn,
                "gridData": [...sendArr]
            }
            dispatch(addGridData(GridData))
            // setValid(false)
            // window.history.back()
            // displayMessage.success("success grid added successfully");

        }
        // console.log(tempArr);
        // let addGridData = {
        //     "floorId": floorData.floorId,
        //     "warehouseId": floorData.warehouseId,
        //     "row": maxRow,
        //     "col": maxColumn,
        //     "gridData": [...tempArr]
        // }
        // console.log(addGridData, "avadakedavra");
        // console.log(maxColumn, maxRow, "maxcoulumn", tempArr);




        // console.log({ "grid": tempArr });

    }

    const checkGrids = (col, row) => {

        let arrfi = tempArr.find(arr => arr.rowIndex < row && arr.colIndex < col && arr.type == "NA")
        if (arrfi == undefined) {
            setValid(true)
        }
        else {
            setValid(false)
        }

    }
    const renameGrid = (cellId) => {
        let gridBlock = tempArr.find(el => el.cellId == cellId)
        setInputs({ labelInput: gridBlock.name, typeDropdown: gridBlock.type })
        // let labelInput = inputs.labelInput
        // gridBlock.name = labelInput ? labelInput.toUpperCase() : ""
    }


    useEffect(() => {
        if (!floorData) {
            window.history.back()
        }

    }, [floorData])

    useEffect(() => {
        if (message || error) {
            message && window.history.back()
            error  &&  notification.error({
                message: "Error",
                description: error,
                placement: 'bottomRight'
              })
        }
       

    }, [message,error])

    if (!floorData) {
        return null
    }
    return (
        <>
            <Card title="Floor Details" className='gx-shadow-less ' headStyle={{ fontSize: '1.5rem' }} style={{ overflow: 'hidden' }}>

                <div className="gx-ml-1 gx-flex-row gx-justify-content-between">
                    <div className="gx-d-flex">
                        <div className="label-inpt-row gx-mr-2">
                            <label>Enter Number of rows</label>
                            <InputNumber style={{ width: "100%" }} min={1} name="rows" placeholder="No. of Rows" onChange={handleRow} />
                        </div>
                        <div className="label-inpt-row gx-mr-2">
                            <label>Enter Number of columns</label>
                            <InputNumber style={{ width: "100%" }} min={1} name="columns" placeholder="No. of Column" onChange={handleColumn} />
                        </div>
                        <div className="gx-mt-16">
                            <Button onClick={() => makeGrid()} className='gx-shadow-less gx-pr-4 gx-pl-4' type="primary">Submit</Button>
                        </div>
                    </div>
                    <div className="gx-mt-16"><Link to={`/detailPage/${floorData.companyName}`}><Button type="primary" className="gx-shadow-less gx-pr-4 gx-pl-4">
                        <i
                            className="fa fa-arrow-circle-left gx-mr-2 "
                            aria-hidden="true"
                        ></i>
                        Back
                    </Button></Link></div>
                </div>

                {task &&
                    <div className='left-section gx-flex-row gx-justify-content-start'>
                        <div className="gx-mr-sm-2 grid-mt-10">
                            <select id="inpt-v" name="typeDropdown" value={inputs.typeDropdown || ""} onChange={handleChange}>
                                <option value="block">Block</option>
                                <option value="space">Space</option>
                                <option value="merge_block">Merge Block</option>
                            </select>
                        </div>
                        <div className="gx-mr-sm-2 grid-mt-10">
                            <Input type="text" name="rowsCount" value={inputs.rowsCount || ""} placeholder="No. of Rows Merge(when single cell selected)" onChange={handleChange} />
                        </div>
                        <div className="gx-mr-sm-2 grid-mt-10">
                            <Input type="text" name="columnCount" value={inputs.columnCount || ""} placeholder="No. of Column Merge(when single cell selected)" onChange={handleChange} />
                        </div>
                        <div className="gx-mr-sm-2 grid-mt-10">
                            <Input type="text" name="labelInput" value={inputs.labelInput || ""} placeholder="New Label" onChange={handleChange} />
                        </div>
                        <div className="gx-mr-sm-2 grid-mt-10">
                            <Input type="text" name="fontColor" value={inputs.fontColor || ""} placeholder="Font Color" onChange={handleChange} />
                        </div>
                      
                        <div className="gx-mr-sm-2 gx-d-flex grid-mt-10">
                            <Input type="number" name="length" value={inputs.length || ""} placeholder="Height of Block" onChange={handleChange} />
                            <select id="inpt-v2" disabled={true} name="unitDropdown" value={inputs.unitDropdown || ""} onChange={handleChange}>

                                <option value="meter">meter</option>
                                <option value="feet">feet</option>
                                <option value="yard">yard</option>
                            </select>
                        </div>
                        <div className="gx-mr-sm-2 gx-d-flex grid-mt-10">
                            <Input type="number" name="width" value={inputs.width || ""} placeholder="Width of Block" onChange={handleChange} />
                            <select id="inpt-v2"disabled={true} name="unitDropdown" value={inputs.unitDropdown || ""} onChange={handleChange}>
                                <option value="meter">meter</option>
                                <option value="feet">feet</option>
                                <option value="yard">yard</option>
                            </select>
                        </div>
                       
                        <div className="gx-mr-sm-2 gx-d-flex grid-mt-10">
                            <label >Background Color:</label>
                            <Input type="color" name="bgColor" width={'30%'} value={inputs.bgColor || ""} placeholder="Background Color" onChange={handleChange} />
                        </div>
              
                        <div className="gx-d-flex grid-mt-10">
                            <div className="gx-mr-sm-2 gx-ml-sm-2" >
                                <Button id="submitButton" disabled={!inputs.labelInput && !inputs.length && !inputs.width} onClick={() => submitHandle()} className='gx-shadow-less gx-pr-4 gx-pl-4' type="primary">Submit</Button>
                            </div>
                            <div className="gx-mr-sm-2 gx-ml-sm-2"><Button id="submitButton" onClick={() => printArray()} className='gx-shadow-less gx-pr-4 gx-pl-4' type="primary">Add Grid</Button>
                            </div>
                        </div>
                    </div>}

                <div className="gx-m-60">
                    <table id="tableData">
                        <tbody>
                            {Array.from({ length: grid.rows ? grid.rows : '' }, (_, i) => {
                                return (
                                    <tr>
                                        {Array.from({ length: grid.columns ? grid.columns : '' }, (_, j) => {
                                            let gridBlock = getBlockByIndex(i, j)
                                            if (!gridBlock) {
                                                return null;
                                            }
                                            {
                                                var rowwidth = gridBlock.mergeRows
                                            }

                                            return (
                                                <td
                                                    className={"vari-height vari-width"}
                                                    onClick={() => updateGrid(gridBlock.cellId)}
                                                    onMouseEnter={(event)=>{if(event.buttons==1){
                                                        updateGrid(gridBlock.cellId)
                                                        event.preventDefault()
                                                    }}}
                                                    colSpan={gridBlock.mergeColumn}
                                                    rowSpan={gridBlock.mergeRows}

                                                    style={{
                                                        "--var-width": gridBlock.mergeColumn || 1,
                                                        "--var-height": gridBlock.mergeRows || 1,
                                                        background: gridBlock.isSelected ? '#ff7200' : gridBlock.bgColor,
                                                        color: gridBlock.isSelected ? "" : gridBlock.fontColor,
                                                        position:"relative"
                                                        
                                                    }}

                                                >
                                                    {gridBlock.length && gridBlock.width && <div className="top-left-div">{gridBlock.length + "x" + gridBlock.width}</div>}
                                                    <span className="td-head-name" title="Rename" onClick={() => renameGrid(gridBlock.cellId)}>{gridBlock.name}</span>
                                                    <div className="copy-updn">
                                                        {(gridBlock.mergeRows || gridBlock.mergeColumn) && <span onClick={(evt) => {
                                                            evt.preventDefault()
                                                            evt.stopPropagation()
                                                            copyLayout(gridBlock.cellId)
                                                        }} className="inside-grid">copy</span>}
                                                        {isCopied && <span onClick={(evt) => {
                                                            evt.preventDefault()
                                                            evt.stopPropagation()
                                                            pasteLayout(gridBlock.cellId)
                                                        }} className="inside-grid">paste</span>}
                                                        {(gridBlock.name || gridBlock.mergeRows || gridBlock.mergeColumn) && <span onClick={(evt) => {
                                                            evt.preventDefault()
                                                            evt.stopPropagation()
                                                            unSelect(gridBlock.cellId)
                                                        }} className="inside-grid ">clear</span>}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    )
}