import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE, 
    GET_TEMPERATURE_DATA
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'

  export const getTemperatureData = (obj) => {
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('track/getTemperatureData',obj
      ).then(({data}) => {
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          // dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({type: GET_TEMPERATURE_DATA, payload: data});
        } 
        else 
        {
          dispatch({type: GET_TEMPERATURE_DATA, payload: {}});
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
