import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Zone from "./Zone";
import User from "./User";
import Asset from "./Asset";
import Map from "./Map";
import WmsUser from "./WmsUser";
import Warehouse from "./Warehouse";
import DynamicForm from "./dynamicForm";
import AreaMapping from "./AreaMapping";
import Analytics from "./Analytics";
import DamageCollection from "./DamageCollection";
import Superadmin from "./Superadmin";
import jobDash from "./jobdash";
import history from "./history";
import Dashboard from './Dashboard';
import Report from './Report';
import SearchTag from "./SearchTag"

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  commonData: Common,
  zoneData: Zone,
  userData: User,
  assetData: Asset,
  mapData: Map,
  wmsUserData: WmsUser,
  warehouseData: Warehouse,
  DynamicFormData: DynamicForm,
  AreaMappingData: AreaMapping,
  AnalyticsData: Analytics,
  DamageCollection: DamageCollection,
  superadmin: Superadmin,
  jobdash: jobDash,
  history: history,
  dashboard:Dashboard,
  ReportData:Report,
  SearchTag:SearchTag
});

export default reducers;
