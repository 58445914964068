import React from 'react';
import { Link } from "react-router-dom";

const RightNav = ({ open }) => {

  const getClass=(key="")=>{
    let path=window.location.pathname.split("/")
    // console.log(path,'path');
    if(path && ((!path[1] && key=="home") || (path[1] && path[1]==key))){
      return true
    }
    
    else{
      return false
    }


    
  }
  return (
    <ul className={open?'web-ul2':'web-ul'} open={open}>
      <li ><Link to='/' style={getClass('home')?styles.borderBottom:styles.none}>Home</Link></li>
      <li ><Link to='/aboutUs' style={getClass("aboutUs")?styles.borderBottom:styles.none}>About Us</Link></li>
      <li ><Link to='/contactUs' style={getClass("contactUs")?styles.borderBottom:styles.none}>Contact Us</Link></li>
      <li><Link to='/signin'><div className="web-login">
        <div className="login-logo"><i class="fa fa-user" aria-hidden="true"></i></div><div>
          LOGIN</div> </div></Link></li>
    </ul>
  )
}

const styles = {
  borderBottom: {      
    borderBottom:"2px solid grey",  
    paddingBottom:"0" 
  },
  none: {

  }
}
export default RightNav
