import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET,GET_USER_DETAIL} from "../../constants/ActionTypes";

const INIT_STATE = {
  token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : "",
  initURL: '',
  authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      window.localStorage.clear()
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    // case USER_DATA: {
      
    //   return {
    //     ...state,
    //     authUser: action.payload,
    //   };
    // }

    case USER_TOKEN_SET: {
      // console.log("set token",action.user)
      return {
        ...state,
        token: action.payload,
        // user:action.user
      };
    }
    case GET_USER_DETAIL: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    default:
      return state;
  }
}
