import {
  EDIT_JOBTYPE,
  GET_SUPER_ADMIN,
  GET_COMPANY,
  ADD_COMPANY,
  DELETE_JOBTYPE,
  GET_JOBTYPE
} from "../../constants/ActionTypes";


const intialState = {
  jobtype: [],
  joblist: []
};

const jobReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case GET_SUPER_ADMIN:
      return { ...state, superadmin: payload };
    case GET_JOBTYPE:
      return { ...state, jobtype: payload };
    case EDIT_JOBTYPE:
      return { ...state, jobtype: payload };
    case DELETE_JOBTYPE:
      return {
        ...state,
        jobtype: state.jobtype.filter(({ _id }) => _id !== payload)
      };
    default:
      return state;
  }
};
export default jobReducer;
