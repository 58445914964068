import {
    GET_ZONE_USER,
    TRACK_USER
} from "../../constants/ActionTypes";


const intialState = {
    allZoneUser: [],
    trackdata:[]
};


const mapReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_ZONE_USER:
            //console.log(payload.data,'reducer')
            return { ...state, allZoneUser: payload.data }
        case TRACK_USER:
            //console.log(payload,'reducer')
            return { ...state,trackdata:payload}

        default:
            return state;
    }
}
export default mapReducer;