import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    GET_ASSET_USER,
    ADD_USER,
    ASSET_ALLOCATION
  } from "../../constants/ActionTypes";


  const intialState = {
    allUser:[]
 };
 
 
  const userReducer = (state = intialState, { type, payload }) => {
     switch (type) {
         case GET_ASSET_USER:
             //console.log(payload,'reducer')
             return { ...state,
                allUser:payload
             }
         default:
             return state;
     }
 }
 export default userReducer;