import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    GET_ASSET_USER,
    ADD_USER,
    ASSET_ALLOCATION
  } from "../../constants/ActionTypes";
  import axios from '../../util/Api'

  export const getAssetUser = (obj) => {
    return async (dispatch) => {
    
      dispatch({type: FETCH_START});
      await axios.post('assetUser/getUsers',obj
      ).then(({data}) => {
      // console.log('get user',data)
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          dispatch({type: GET_ASSET_USER, payload: data.data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  
  //asset/assignDevice
  export const assetAllocationDeAllocation = (obj) => {
    return async (dispatch) => {
      //console.log('allocatin action',obj);
      dispatch({type: FETCH_START});
      await axios.post('asset/assignUser',obj
      ).then(({data}) => {
        //console.log('assetAllocation',data)
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          //dispatch({type: ASSET_ALLOCATION, payload: data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const addUser = (obj) => {
    return async (dispatch) => {
      //console.log('adduser',obj);
      dispatch({type: FETCH_START});
      await axios.post('users/createUser',obj
      ).then(({data}) => {
        //console.log('adduser',data)
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});

        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  export const deleteUser = (obj) => {
    return async (dispatch) => {
      //console.log('edituser',obj);
      dispatch({type: FETCH_START});
      await axios.post('assetUser/deleteUser',obj
      ).then(({data}) => {
        //console.log('edituser',data)
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };

  export const editAsset = (obj) => {
    return async (dispatch) => {
      //console.log('edituser',obj);
      dispatch({type: FETCH_START});
      await axios.post('asset/editAsset',obj
      ).then(({data}) => {
        //console.log('edituser',data)
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          //dispatch({type: ASSET_ALLOCATION, payload: data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  export const assetAllocation= (obj) => {
   console.log('allocatin action',obj);
    return async (dispatch) => {
     
      dispatch({type: FETCH_START});
      await axios.post('asset/assignThing',obj
      ).then(({data}) => {
        //console.log('assetAllocation',data)
        if (data.success) 
        {
          dispatch({type: FETCH_SUCCESS,payload: data.success});
          dispatch({type: SHOW_MESSAGE, payload: data.message});
          //dispatch({type: ASSET_ALLOCATION, payload: data});
        } 
        else 
        {
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
