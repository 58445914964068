// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";

//Zone constant
export const GET_ZONE = "GET_ZONE";
export const CREATE_ZONE = "CREATE_ZONE";
export const EDIT_ZONE = "EDIT_ZONE";
export const DELETE_ZONE = "DELETE_ZONE";
export const GET_TRACK_BY_ZONE = "GET_TRACK_BY_ZONE";

//User constant
export const GET_ASSET_USER = "GET_ASSET_USER";
export const ADD_USER = "ADD_USER";

export const ASSET_ALLOCATION = "ASSET_ALLOCATION";

//Asset constant
export const ADD_GATEWAY = "ADD_GATEWAY";
export const ADD_ASSET = "ADD_ASSET";
export const GET_GATEWAY = "GET_GATEWAY";
export const GET_ASSET = "GET_ASSET";
export const DELETE_GATEWAY = "DELETE_GATEWAY";
export const GET_TRACK_BY_DEVICE = "GET_TRACK_BY_DEVICE";

//Get all zone user(MAP)
export const GET_ZONE_USER = "GET_ZONE_USER";
export const TRACK_USER = "TRACK_USER";

// warehouse
export const GET_FILLED_LOCATION = "GET_FILLED_LOCATION";
export const GET_ALL_SHIPMENT_BY_LOCATION = "GET_ALL_SHIPMENT_BY_LOCATION";

// Shipment
export const GET_ALL_SHIPMENT = "GET_ALL_SHIPMENT";
export const SHIPMENT_COUNT_STATUS = "SHIPMENT_COUNT_STATUS";

//Bonded register(viewRegister)
export const GET_ALL_REGISTER = "GET_ALL_REGISTER";

//Wms User
export const GET_SHIPMENT_USER = "GET_SHIPMENT_USER";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const CREATE_SHIPMENT_USER = "CREATE_SHIPMENT_USER";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const GET_DUTY_LOGS = "GET_DUTY_LOGS";

//Domestic Shipment
export const GET_ALL_DOMESTIC_SHIPMENT = "GET_ALL_DOMESTIC_SHIPMENT";

//Bonded Shipment
export const GET_ALL_BONDED_SHIPMENT = "GET_ALL_BONDED_SHIPMENT";

//super admin
export const GET_SUPER_ADMIN = "GET_SUPER_ADMIN";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const GET_WAREHOUSE = "GET_WAREHOUSE";
export const GET_FLOOR = "GET_FLOOR";
export const GET_ALL = "GET_ALL";
export const ADD_COMPANY = "ADD_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const GET_ADMIN = "GET_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_FLOORS ="GET_FLOORS";
export const SET_FLOOR ="SET_FLOOR";
export const ADD_GRID ="ADD_GRID";
export const GET_ALL_PERMISSIONS ="GET_ALL_PERMISSIONS";
export const GENERATE_QR ="GENERATE_QR";
export const GET_WAREHOUSE_ANALYTICS ="GET_WAREHOUSE_ANALYTICS";
export const GET_FLOOR_ANALYTICS ="GET_FLOOR_ANALYTICS";
export const GET_ALL_ANALYTICS ="GET_ALL_ANALYTICS";
export const GET_DATE_ANALYTICS ="GET_DATE_ANALYTICS";




// Form Creater
export const GET_COMPONENT_LIST = "GET_COMPONENT_LIST";
export const GET_REGEX_LIST = "GET_REGEX_LIST";
export const GET_API_LIST = "GET_API_LIST";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const GET_TEMPLATE_LIST = "GET_TEMPLATE_LIST";
export const GET_FREE_TEMPLATE = "GET_FREE_TEMPLATE";
export const GET_PACKAGE_DETAIL_TEMPLATE = "GET_PACKAGE_DETAIL_TEMPLATE";
export const GET_TEMPLATE_HEADER = "GET_TEMPLATE_HEADER"


//Form render to add the data   save_template_data
export const GET_TEMPLATE_DETAIL = "GET_TEMPLATE_DETAIL";
export const SAVE_TEMPLATE_DATA = "SAVE_TEMPLATE_DATA";
export const GET_TEMPLATE_DATA = "GET_TEMPLATE_DATA";
export const GET_TEMPLATE_DATA_BY_PRODUCT_TYPE ="GET_TEMPLATE_DATA_BY_PRODUCT_TYPE";
export const SAVE_BULK_DATA ="SAVE_BULK_DATA";

//shipment/fill_location_by_shipment
export const GET_TEMPLATE_DATA_BY_SHIPMENT = "GET_TEMPLATE_DATA_BY_SHIPMENT";
export const GET_TEMPLATE_DATA_BY_LOCATION = "GET_TEMPLATE_DATA_BY_LOCATION";
export const GET_GRID_BLOCK_DATA_BY_LOCATION = "GET_GRID_BLOCK_DATA_BY_LOCATION";
export const FILL_SHIPMENT_BY_LOCATION = "FILL_SHIPMENT_BY_LOCATION";
export const VIEW_FILLED_LOCATION_BY_SHIPMENT ="VIEW_FILLED_LOCATION_BY_SHIPMENT";



//Report 
export const GET_SHIPMENT_REPORT = "GET_SHIPMENT_REPORT";
export const GET_WMS_REPORT = "GET_WMS_REPORT";
export const GET_WMS_REPORT_EXCEL = "GET_WMS_REPORT_EXCEL";
export const GET_WMS_REPORT_PDF = "GET_WMS_REPORT_PDF";


//area mapping
export const GET_MAPPED_AREA = "GET_MAPPED_AREA";
export const GET_MAPPED_ANALYTICS ="GET_MAPPED_ANALYTICS"

//analytics
export const GET_TEMPERATURE_DATA = "GET_TEMPERATURE_DATA";

//seal
export const GET_SEAL_DATA = "GET_SEAL_DATA";

//damage Collection
export const GET_DAMAGE_COLLECTION_DATA = "GET_DAMAGE_COLLECTION_DATA";

export const GET_ALL_PRODUCT_TYPE = "GET_ALL_PRODUCT_TYPE";

//damage Jobs
export const GET_ALL_JOB = "GET_ALL_JOB";
export const GET_JOB_DETAIL = "GET_JOB_DETAIL";


//Package Details
export const GET_PACKAGE_DETAILS = "GET_PACKAGE_DETAILS";


//Product Master VALIDATE_PRODUCT_MASTER_DESCRIPTION,GET_PRODUCT_MASTER
export const GET_PRODUCT_MASTER = "GET_PRODUCT_MASTER";
export const SAVE_PRODUCT_MASTER = "SAVE_PRODUCT_MASTER";
export const VALIDATE_PRODUCT_MASTER_DESCRIPTION = "VALIDATE_PRODUCT_MASTER_DESCRIPTION";

//Grid
export const GET_GRID_VIEW = "GET_GRID_VIEW";
export const GET_GRID_DATA = "GET_GRID_DATA";
export const GET_GRID_CELL_DETAIL = "GET_GRID_CELL_DETAIL";
export const GET_GRID_RACK_DETAIL = "GET_GRID_RACK_DETAIL";
export const GET_GRID_ALL_DETAIL = "GET_GRID_ALL_DETAIL";
export const GET_RACK_DATA = "GET_RACK_DATA";
export const GET_GRID_CELL_DIMENTION = "GET_GRID_CELL_DIMENTION";
export const GET_GRID_RACK_DIMENTION = "GET_GRID_RACK_DIMENTION";
export const GET_GRID_ALL_DIMENTION = "GET_GRID_ALL_DIMENTION";


// JOB DASH
export const GET_JOBTYPE = "GET_JOBTYPE";
export const DELETE_JOBTYPE = "DELETE_JOBTYPE";
export const EDIT_JOBTYPE = "EDIT_JOBTYPE";

// Upload file url
export const GET_FILE_URL = "GET_FILE_URL";
export const GET_SKU_HEADER = "GET_SKU_HEADER";
//HISTORY
export const GET_HISTORY = "GET_HISTORY";
//dynamic form

//Zone constant
export const GET_DASHBOARD = 'GET_DASHBOARD';


//SEARCH TAG constant
export const GET_SEARCH_TAG = 'GET_SEARCH_TAG';

