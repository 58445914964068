import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import Home from "./Home";
import WmsUsers from "./WmsUsers";
import Floor from "./superadmin/Floor/floorGrid"

const App = ({ match, user }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
    <Route
        exact
        path={`${match.url}WMSReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport"))}
            />
          </ProtectedRoute>
        )}
      />
    <Route
        exact
        path={`${match.url}WMSReport/itemMaster`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/itemMasterReport"))}
            />
          </ProtectedRoute>
        )}
      />
    <Route
        exact
        path={`${match.url}WMSReport/outwardReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/outwardReport"))}
            />
          </ProtectedRoute>
        )}
      />
    <Route
        exact
        path={`${match.url}WMSReport/inwardReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/inwardReport"))}
            />
          </ProtectedRoute>
        )}
      />
     
       
        
    <Route
        exact
        path={`${match.url}WMSReport/soWiseReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/soWiseReport"))}
            />
          </ProtectedRoute>
        )}
      />
    <Route
        exact
        path={`${match.url}WMSReport/soPackingListReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/soPackingListReport"))}
            />
          </ProtectedRoute>
        )}
      />
      
    <Route
        exact
        path={`${match.url}WMSReport/inventoryReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/InventuaryReport"))}
            />
          </ProtectedRoute>
        )}
      />
     
        
        
    <Route
        exact
        path={`${match.url}WMSReport/inventoryReport/summaryReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/InventuaryReport/summaryReport"))}
            />
          </ProtectedRoute>
        )}
      />
    <Route
        exact
        path={`${match.url}WMSReport/inventoryReport/USNWise`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/InventuaryReport/USNReport"))}
            />
          </ProtectedRoute>
        )}
      />
    <Route
        exact
        path={`${match.url}WMSReport/inventoryReport/locationReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.reportDownload 
            }
          >
            <Route
              component={asyncComponent(() => import("./WMSReport/InventuaryReport/locationReport"))}
            />
          </ProtectedRoute>
        )}
      />
    


      <Route
        exact
        path={`${match.url}template`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.viewTemplate
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./DynamicForm/FormContainer")
              )}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}template/create`}
        render={() => (
          <ProtectedRoute
            redirectPath={
              user && user.permissions && user.permissions.viewTemplate
                ? "/template"
                : "/home"
            }
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.viewTemplate &&
              user.permissions.createTemplate
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./DynamicForm/FormCreater")
              )}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}template/view/:templateId`}
        render={() => (
          <ProtectedRoute
            redirectPath={
              user && user.permissions && user.permissions.viewTemplate
                ? "/template"
                : "/home"
            }
            isAllowed={
              user && user.permissions && user.permissions.viewTemplate
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./DynamicForm/AddDataToForm_New")
              )}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}template/edit/:templateId`}
        render={() => (
          <ProtectedRoute
            redirectPath={
              user && user.permissions && user.permissions.viewTemplate
                ? "/template"
                : "/home"
            }
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.viewTemplate &&
              user.permissions.editTemplate
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./DynamicForm/FormEditor")
              )}
            />
          </ProtectedRoute>
        )}
      />

       <Route
        exact
        path={`${match.url}viewMasterInventory`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.viewMasterInventory}
          >
            <Route component={asyncComponent(() => import("./Inventory/Master/index"))} />
          </ProtectedRoute>
        )}
      />
      
      <Route
        exact
        path={`${match.url}inventory`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && (
              user.permissions.viewInventory  || 
              user.permissions.shipmentReport || 
              user.permissions.viewMasterInventory)}
          >
            <Route component={asyncComponent(() => import("./Inventory"))} />
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path={`${match.url}packageDetail`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && 
              (user.permissions.viewPackageDetail)}
          >
            <Route component={asyncComponent(() => import("./PackageDetail"))} />
          </ProtectedRoute>
        )}
      />
       <Route
        exact
        path={`${match.url}packagedetail/viewData/:templateID`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.viewPackageDetail}
          >
            <Route component={asyncComponent(() => import("./PackageDetail/ViewFormData"))} />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}productMaster`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && 
              (user.permissions.viewProductMasterData || user.permissions.addProductMasterData)}
          >
            <Route component={asyncComponent(() => import("./ProductMaster"))} />
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path={`${match.url}productMaster/viewData/:templateID`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.viewProductMasterData}
          >
            <Route component={asyncComponent(() => import("./ProductMaster/ViewFormData"))} />
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path={`${match.url}productMaster/addData/`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.addProductMasterData}
          >
            <Route component={asyncComponent(() => import("./ProductMaster/AddData/index"))} />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}dataEntry`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.dataEntry
            }
          >
            <Route
              component={asyncComponent(() => import("./DataEntry"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}shipmentReport`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.shipmentReport
            }
          >
            <Route
              component={asyncComponent(() => import("./Inventory/Report"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}viewDutyLogs`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={user && user.permissions && user.permissions.viewDutyLogs}
          >
            <Route
              component={asyncComponent(() =>
                import("./WmsUsers/DutyLogs/index")
              )}
            />
          </ProtectedRoute>
        )}
      />
      
      <Route
        path={`${match.url}viewFormData/:shipmentID`}
        render={() => (
          <ProtectedRoute
            redirectPath={"/home"}
            isAllowed={user && user.permissions && user.permissions.dataEntry}
          >
            <Route
              component={asyncComponent(() =>
                import("./Inventory/ViewFormData")
              )}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}analytics`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.analytics}
          >
            <Route component={asyncComponent(() => import("./IndoorTracking/Analytics"))} />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}dashboardView`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.dashboard}
          >
            <Route
              component={asyncComponent(() => import("./DashBoardView"))}
            />
          </ProtectedRoute>
        )}
      />
    
      <Route
        path={`${match.url}home`}
        render={() => (
          <ProtectedRoute redirectPath="/home" isAllowed={true}>
            <Route component={asyncComponent(() => import("./Home"))} />
          </ProtectedRoute>
        )}
      />

     
      <Route
        path={`${match.url}newBase`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.new_gridView
            }
          >
            <Route component={asyncComponent(() => import("./GridView"))} />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}warehouseJobs`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.viewWarehouseJobs
            }
          >
            <Route
              component={asyncComponent(() => import("./WarehouseJobs"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}warehouseBoxAllocation/:shipmentId`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.viewWarehouseJobs &&
              user.permissions.gateIngateOut
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./WarehouseJobs/WarehouseBoxAllocation/index")
              )}
            />
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path={`${match.url}changeShipmentLocation/:shippingID`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user &&
              user.permissions &&
              user.permissions.viewWarehouseJobs &&
              user.permissions.gateIngateOut
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./WarehouseJobs/WarehouseBoxAllocation/index")
              )}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}areaMapping`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.areaMapping}
          >
            <Route
              component={asyncComponent(() => import("./AreaMapping/index"))}
            />
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path={`${match.url}areaMappinganalytics`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.areaMapping}
          >
            <Route
              component={asyncComponent(() => import("./AreaMappinganalytics/index"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}areaMapping/materialAllocation`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.areaMapping}
          >
            <Route
              component={asyncComponent(() =>
                import("./AreaMapping/MaterialAreaAllocation")
              )}
            />
          </ProtectedRoute>
        )}
      />
      
      <Route
        exact
        path={`${match.url}zoneMapping`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.zoneMapping}
          >
            <Route
              component={asyncComponent(() => import("./ZoneMapping/index"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}zoneMapping/zoneAreaAllocation`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.zoneMapping}
          >
            <Route
              component={asyncComponent(() =>
                import("./ZoneMapping/ZoneAreaAllocation")
              )}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}user`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user &&
              user.permissions &&
              (user.permissions.viewUsers || user.permissions.createUsers || user.permissions.viewDutyLogs)
            }
          >
            <WmsUsers />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}createUser`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.createUsers}
          >
            <Route
              component={asyncComponent(() => import("./WmsUsers/AddUsers/index"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}viewUsers`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && user.permissions.viewUsers}
          >
            <Route
              component={asyncComponent(() => import("./WmsUsers/ViewUsers/index"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}indoorTracking`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
           <Route
              component={asyncComponent(() => import("./IndoorTracking/index"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}zones`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() => import("./IndoorTracking/Zones"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}assets`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() => import("./IndoorTracking/Assets"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}devices`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route component={asyncComponent(() => import("./IndoorTracking/Devices"))} />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}map`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() => import("./IndoorTracking/Map"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}tracking/:trackID`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route component={asyncComponent(() => import("./IndoorTracking/Map/Tracking"))} />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}devicereport/:reportID`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() => import("./IndoorTracking/Devices/deviceReport"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        path={`${match.url}zonereport/:reportID`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() => import("./IndoorTracking/Zones/ZoneReport"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}reports`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route component={asyncComponent(() => import("./IndoorTracking/Report"))} />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}reports/assetTravelReport`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./IndoorTracking/Report/AssetTravelReport")
              )}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}reports/zoneSummary`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() => import("./IndoorTracking/Report/ZoneSummary"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}reports/zoneSummaryReportForAsset`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./IndoorTracking/Report/ZoneWiseSummaryForAsset")
              )}
            />
          </ProtectedRoute>
        )}
      />
      
      <Route
        exact
        path={`${match.url}sealAllocation/:shippingID`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.rfidSealMapping
            }
          >
            <Route
              component={asyncComponent(() => import("./SealConfig/SealAllocation"))}
            />
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path={`${match.url}tagExcelUpload`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.rfidSealMapping
            }
          >
            <Route
              component={asyncComponent(() => import("./SealConfig/SealUpload/index"))}
            />
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path={`${match.url}gridModification`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.gridModification
            }
          >
            <Route
              component={asyncComponent(() =>
                import("./GridModification/GridModifier")
              )}
            />
          </ProtectedRoute>
        )}
      />
      

      <Route
        path={`${match.url}superadmin`}
        
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.superAdmin && user.userType=="superadmin"
            }
          >
            <Route
              component={asyncComponent(() => import("./superadmin/index"))}
            /> 
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.url}detailPage/:companyName`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.superAdmin && user.userType=="superadmin"
            }
          >
            <Route
              component={asyncComponent(() => import("./superadmin/Company/index"))}
            />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.url}floorgrid`}
        render={(props) => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.superAdmin && user.userType=="superadmin"
            }
          >
            <Floor />
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path={`${match.url}jobs`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={user && user.permissions && (user.permissions.viewJobs || user.permissions.jobAllocation)}
          >
            <Route
              component={asyncComponent(() => import("./jobdash/JobListDashboard/index"))}
            />
          </ProtectedRoute>
        )}
      />
      <Route
        path={`${match.url}jobDashboard`}
        isAllowed={user && user.permissions && (user.permissions.viewJobs || user.permissions.jobAllocation)}
        render={() => (
          <Route
            component={asyncComponent(() => import("./jobdash/index"))}
          />
        )}
      />
      <Route
        path={`${match.url}job/type`}
        isAllowed={user && user.permissions && (user.permissions.jobAllocation)}
        render={() => (
          <Route component={asyncComponent(() => import("./jobdash/JobTypeDashboard/index"))} />
        )}
      />
      <Route
        path={`${match.url}job/allocate`}
        isAllowed={user && user.permissions && (user.permissions.jobAllocation)}
        render={() => (
          <Route component={asyncComponent(() => import("./jobdash/JobAllocation/index"))} />
        )}
      />
      <Route
        path={`${match.url}history/:shipmentId`}
        render={() => (
          <Route component={asyncComponent(() => import("./history/index"))} />
        )}
      />
       <Route
        exact
        path={`${match.url}main_dashboard`}
        render={() => (
          <ProtectedRoute
            redirectPath="/home"
            isAllowed={
              user && user.permissions && user.permissions.indoorTracking
            }
          >
            <Route
              component={asyncComponent(() => import("./IndoorTracking/Main_dashboard"))}
            />
          </ProtectedRoute>
        )}
      />

     
    </Switch>
  </div>
);

export default App;

const ProtectedRoute = ({ isAllowed, redirectPath = "/home", children }) => {
  if (!isAllowed) {
    return <Redirect to={redirectPath} replace />;
  }
  //return children;
  return children ? children : <Home />;
};
