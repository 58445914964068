import { GET_HISTORY, FETCH_SUCCESS, SHOW_MESSAGE, FETCH_ERROR, FETCH_START } from "../../constants/ActionTypes";
import axios from "../../util/Api";


export const getHistory = obj => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/dynamicShipment/getHistory", obj)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: SHOW_MESSAGE, payload: data.message });
                    dispatch({ type: GET_HISTORY, payload: data.data });
                } else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    };
};