import {
    GET_TEMPERATURE_DATA
} from "../../constants/ActionTypes";



const intialState = {
    temperatureData:{}
};


const anlyticsReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_TEMPERATURE_DATA:
            return { ...state, temperatureData:payload }
        
        default:
            return state;
    }
}
export default anlyticsReducer;