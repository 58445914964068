import {
    GET_MAPPED_AREA,
    GET_MAPPED_ANALYTICS
} from "../../constants/ActionTypes";



const intialState = {
    mappedArea:{},
    mappedAnalytics:[]
};


const areaMappingReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_MAPPED_AREA:
            return { ...state, mappedArea:payload }
        
        case GET_MAPPED_ANALYTICS:
            return { ...state, mappedAnalytics:payload }
            
        default:
            return state;
    }
}
export default areaMappingReducer;