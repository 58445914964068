import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  GET_USER_DETAIL
} from "../../constants/ActionTypes";
import axios from '../../util/Api';


export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['access-token'] = data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password}) =>
{
  return (dispatch) =>
  {
    dispatch({type: FETCH_START});
    axios.post('users/login', {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      if (data.success) 
      {
        afterLoginProcess();
      } 
      else 
      {
        dispatch({type: FETCH_ERROR, payload: data.message});
      }

      function afterLoginProcess() 
      {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("user", JSON.stringify(data.user));
        axios.defaults.headers.common['authorization'] = data.token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token, user: data.user });
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/me',
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("shipmentID")
  localStorage.removeItem('user')
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    dispatch({type: FETCH_SUCCESS});
    axios.post('users/logout')
  }
};

export const getUserDetail= (obj) => {
     
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('users/getUserDetail',obj
    ).then(({data}) => {     
      if (data.success) 
      {  
        dispatch({type: FETCH_SUCCESS});
        localStorage.setItem("user", JSON.stringify(data.user));
        dispatch({type: GET_USER_DETAIL, payload: data.user});
      } 
      else 
      {
        dispatch({type: FETCH_ERROR, payload: data.message});
        localStorage.removeItem('user')
        dispatch({type: GET_USER_DETAIL, payload: {}})
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};