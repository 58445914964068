import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Home = () => {
  const iconFontSize = "gx-fs-2xl ";
  const iconColor = "gx-text-white";

  const { TemplateList } = useSelector(state => state.DynamicFormData);
  const [cardData, setCardData] = useState([]);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    let tempData = [...cardData];
    let userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userId !== userData._id) {
      setUserId(userData._id);

      if (userData && userData.permissions) {
        if (userData.permissions.dashboard) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-bars",
            background: "gx-bg-sepia",
            link: "/dashboardView",
            title: "Dashboard"
          });
        }
        if (userData.permissions.new_gridView) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-delicious ",
            background: "gx-bg-warning",
            link: "/newBase",
            title: "Grid View"
          });
        }
        if (
          userData.permissions.createUsers ||
          userData.permissions.viewUsers ||
          userData.permissions.viewDutyLogs
        ) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-user",
            background: "gx-bg-indigo",
            link: "/user",
            title: "Users"
          });
        }
        if (userData.permissions.indoorTracking) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-map-o ",
            background: "gx-bg-pink",
            link: "/indoorTracking",
            title: "Indoor Tracking Dashboard"
          });
        }
       
        if (userData.permissions.viewTemplate) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-th-large",
            background: "gx-bg-red",
            link: "/template",
            title: "Templates"
          });
        }
        if (userData.permissions.dataEntry) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-database",
            background: "gx-bg-teal",
            link: "/dataEntry",
            title: "Data Entry"
          });
        }
        if (userData.permissions.viewInventory || userData.permissions.shipmentReport || userData.permissions.viewMasterInventory) {
            tempData.push({
              key: Date.now(),
              icon: "fa fa-archive",
              background: "gx-bg-sepia",
              link: "/inventory",
              title: "Inventory"
            });
        }
       
        if (userData.permissions.viewProductMasterData || userData.permissions.addProductMasterData) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-database",
            background: "gx-bg-brown",
            link: "/productMaster",
            title: "Item Master"
          });
        }
        if (userData.permissions.viewPackageDetail) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-database",
            background: "gx-bg-cyan",
            link: "/packageDetail",
            title: "Package Detail"
          });
        }
        if (userData.permissions.reportDownload) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-file-excel-o",
            background: "gx-bg-geekblue",
            link: "/WMSReport",
            title: "Report Dashboard"
          });
        }

        // if (userData.permissions.viewWarehouseJobs) {
        //   tempData.push({
        //     key: Date.now(),
        //     icon: "fa fa-tasks",
        //     background: "gx-bg-sepia",
        //     link: "/warehouseJobs",
        //     title: "Warehouse Jobs"
        //   });
        // }
        if (userData.permissions.areaMapping) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-window-restore",
            background: "gx-bg-info",
            link: "/areaMapping",
            title: "Area Mapping"
          });
        }
        if (userData.permissions.areaMappingAnalytices) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-window-restore",
            background: "gx-bg-pink",
            link: "/areaMappinganalytics",
            title: "Area Mapping Analytics"
          });
        }
        if (userData.permissions.gridModification) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-y-combinator",
            background: "gx-bg-sepia",
            link: "/gridModification",
            title: "Grid Modification"
          });
        }
        if (userData.permissions.jobAllocation || userData.permissions.viewJobs) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-bars",
            background: "gx-bg-orange",
            link: "/jobDashboard",
            title: "Jobs dashboard"
          });
        }
        if (userData.permissions.rfidSealUpload) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-file-excel-o",
            background: "gx-bg-teal",
            link: "/tagExcelUpload",
            title: "Tag Management"
          });
        }

        if (userData.permissions.zoneMapping) {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-cogs",
            background: "gx-bg-amber",
            link: "/zoneMapping",
            title: "Zone Mapping"
          });
        }
        // if (userData.permissions.analytics) {
        //   tempData.push({
        //     key: Date.now(),
        //     icon: "fa fa-line-chart",
        //     background: "gx-bg-brown",
        //     link: "/analytics",
        //     title: "Analytics"
        //   });
        // }
        
       
        if (userData.permissions.superAdmin && userData.userType=="superadmin") {
          tempData.push({
            key: Date.now(),
            icon: "fa fa-user",
            background: "gx-bg-sepia",
            link: "/superadmin",
            title: "Super Admin"
          });
        }
        
        setCardData(tempData);
      }
    }
  }, [TemplateList]);

  return (
    <div className="gx-mr-sm-5 gx-ml-sm-5 gx-ml-sm-2 gx-mt-2 gx-mb-5" data-testid="home-page-after-login">
      <Row gutter={[{ xs: 32, sm: 28, md: 26, lg: 24 }, 17]} className="row">
        {cardData &&
          cardData.map((ele, index) => {
            return (
              <Col className="column" flex="1 0 25%" span={6} key={index}  data-testid="home-column-dashboard">
                <Link to={ele.link} className="gutter-box">
                  <Card
                    style={{
                      width: "100%",
                      height: 140,
                      margin: 0
                    }}
                    className="gx-card gx-hvr-float-shadow gx-p-1"
                    bordered
                  >
                    <div className="gx-d-flex gx-justify-content-center gx-align-items-center gx-flex-column gx-mt-1">
                      <div
                        className={
                          ele.background +
                          " " +
                          "gx-shadow" +
                          " " +
                          "gx-d-flex gx-justify-content-center gx-align-items-center gx-flex-column"
                        }
                        style={styles.divWidth}
                      >
                        <i
                          className={
                            ele.icon + " " + iconFontSize + " " + iconColor
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="gx-mt-3 ">
                        <h4 className="gx-font-family gx-font-weight-one gx-text-center">
                          {ele.title}
                        </h4>
                      </div>
                    </div>
                  </Card>
                </Link>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

const styles = {
  divWidth: {
    width: 55,
    height: 45,
    borderRadius: ".2rem"
  }
};

export default Home;
