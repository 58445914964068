import {GET_ZONE,CREATE_ZONE,EDIT_ZONE,GET_TRACK_BY_ZONE} from "../../constants/ActionTypes";

const intialState = {
   allZone:[],
   zoneTrack:[]
};


 const zoneReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        // case CREATE_ZONE:
        //     return { ...state, ...payload }
        case GET_ZONE:       
            return { ...state,
                allZone:payload
            }
        case EDIT_ZONE:
            return { ...state,
                allZone:[...payload]
            }
        case GET_TRACK_BY_ZONE:
            //console.log(payload,'reducer')
            return { ...state,zoneTrack:payload}
        default:
            return state;
    }
}
export default zoneReducer;